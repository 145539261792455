import { Component } from '@angular/core';

import { KipoService } from '../../core/kipo.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent {
  constructor(protected readonly kipoFService: KipoService) {}
}
