<div class="pb-4 pt-4 print-div">
  <div class="row mt-3 print">
    <div class="col-md-8">
      <dl class="row print-dl">
        <dt class="col-sm-12 pb-1">
          <img
            src="./assets/kipo/logo-kipo-color-1x.png"
            srcset="./assets/kipo/logo-kipo-color-2x.png"
            alt="kipo"
            class="brand footer-image logos" />

          <img
            src="./assets/kipo/logo-paypal-color-1x.png"
            srcset="./assets/kipo/logo-paypal-color-2x.png"
            alt="paypal"
            class="brand-paypal footer-image logos" />

          <img
            *ngIf="template.footer?.partnerLogo"
            src="{{ 'withdraw.footer.partnerLogo' | translate }}"
            srcset="{{ 'withdraw.footer.partnerLogo' | translate }}"
            alt="paypal"
            class="brand footer-image logos" />
        </dt>
        <dt class="col-md-12">
          <span class="copyR">
            {{ 'withdraw.footer.labelCopy' | translate }}</span
          >
        </dt>
        <dt class="col-md-12">
          <span class="copyR">
            {{ 'withdraw.footer.labelCopy2' | translate }}</span
          >
        </dt>
      </dl>
    </div>
    <div class="col-md-4 fullNav">
      <ul class="list-inline pull-right mt-2 d-flex flex-column">
        <div>
          <li class="list-inline-item footer">
            <a
              href="{{ 'withdraw.footer.facebookLink' | translate }}"
              class="link-to-facebook kipoHome-facebookLink"
              rel="noopener"
              target="_blank">
              <img
                src="./assets/kipo/icon-fb-1x.png"
                srcset="./assets/kipo/icon-fb-2x.png"
                alt="facebook"
                class="icon footer-image" />
            </a>
          </li>
          <li class="list-inline-item footer">
            <a
              href="{{ 'withdraw.footer.instagramLink' | translate }}"
              class="kipoFooter-instagramLink"
              target="_blank"
              rel="noopener"
              ><img
                src="assets/kipo/instagram.png"
                class="icon footer-image"
                alt="icon"
            /></a>
          </li>

          <li class="list-inline-item footer">
            <a
              href="https://api.whatsapp.com/send/?phone={{
                'withdraw.disclaimerContactMobile' | translate
              }}"
              class="kipoFooter-whatsappLink"
              target="_blank"
              rel="noopener">
              <img
                src="./assets/kipo/whatsapp-icon.png"
                srcset="./assets/kipo/whatsapp-icon.png"
                alt="whatsapp-icon"
                class="icon footer-image"
            /></a>
          </li>
          <li class="list-inline-item footer">
            <a
              href="{{ 'withdraw.footer.youtubeLink' | translate }}"
              class="link-to-facebook kipoFooter-youtubeLink"
              target="_blank"
              rel="noopener"
              ><img
                src="assets/kipo/youtube-icon.png"
                class="icon footer-image"
                alt="youtube-icon "
            /></a>
          </li>
        </div>
        <li class="list-inline-item nav-item footer">
          <a
            href="{{ 'withdraw.footer.termsLink' | translate }}"
            target="_blank"
            rel="noopener"
            class="nav-link kipo-footerTermsConditions pl-0"
            >{{ 'withdraw.footer.labelTermsConditions' | translate }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>
