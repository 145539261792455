export const environment = {
  disclaimer: true,
  production: false,
  idleSessionTime: 300,
  idleSessionTimeout: 10,
  authorization: {
    domain: 'https://staging.impesa.net/api',
    audience: 'https://api.impesa.net',
    response_type: 'token',
    client_id: '0eaec4544100426ab47f',
    login_uri: '/login',
    logout_uri: '/',
    redirect_uri: '/authorization/callback',
    signUp_uri: '/signup',
  },
  apis: {
    auth: { url: 'https://staging.impesa.net/api' },
    kipo: {
      url: 'https://dev-app.getkipo.com/kipo',
    },
    compliance_api: {
      url: 'https://dev-ms-compliance-k62blhrhka-uc.a.run.app/compliance',
    },
    compliance_proxy: {
      url: 'https://dev-app.getkipo.com/kipo',
    },
    server_message: {
      url: 'https://ms-ws-ngvhdy5roa-uc.a.run.app',
      event: 'ws-ms-event',
      error_event: 'ws-ms-error-event',
    },
  },
  paypal: {
    host: 'https://www.sandbox.paypal.com/signin/authorize',
    responseType: 'code',
    scope: [
      'openid',
      'profile',
      'email',
      'address',
      'https://uri.paypal.com/services/paypalattributes',
      'https://uri.paypal.com/services/wallet/balance-accounts/read',
      'https://uri.paypal.com/transfers/withdrawals',
    ].join(' '),
  },
  captchaKey: '6LdRWkElAAAAAFvwP9QH57fGxO-L82SPVgn0uWkv',
  landingUrl: 'https://dev-country.getkipo.com/',
  cookieName: '_kpst_dev',
};
