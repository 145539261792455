import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PRODUCT_TYPES } from '@enums/product-types.enum';
import { BaseComponent, BootService } from '@impesa/ngx-core';
import { PaypalAccount } from '@models/paypal-accounts';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';

@Component({
  selector: 'app-accounts-balance',
  templateUrl: './accounts-balance.component.html',
  styleUrls: ['./accounts-balance.component.scss'],
})
export class AccountsBalanceComponent extends BaseComponent implements OnInit {
  @Input() _payPalBalance: any;
  @Input() _payPalAccount!: PaypalAccount;
  @Input() _formLoadError!: boolean;
  @Input() _withdrawForm!: UntypedFormGroup;
  @Output() cardValueEmitted = new EventEmitter<boolean>();

  public _viewInTabletMode: boolean = false;
  public _card: boolean = false;
  public _showRequest: boolean = false;
  public _PaypaylIsClicked: boolean = false;
  public _PrepaidIsClicked: boolean = false;
  public _prepaidAccount!: PaypalAccount;
  public _requestId: boolean = false;
  public hasBalance: boolean = false;

  constructor(
    boot: BootService,
    private readonly actRouter: ActivatedRoute,
    private readonly globalService: GlobalVarsService
  ) {
    super(boot);
    this.actRouter.data.subscribe((response: any) => {
      this._prepaidAccount = response.originAccounts?.prepaidAccounts
        ? response.originAccounts?.prepaidAccounts[0]
        : null;

      this.globalService.setPrepaidAccount(this._prepaidAccount);
    });
  }

  ngOnInit(): void {
    if (window.screen.width >= 768 && window.screen.width <= 1024) {
      this._viewInTabletMode = true;
    }
    const _prepaidRequestInfo = this.globalService.getPrepaidRequestInfo();
    const prepaidInfo = this.globalService.getPrepaidAccount();
    this._card = this.validateShowCard(prepaidInfo, _prepaidRequestInfo);
    this._showRequest = _prepaidRequestInfo?.username && !this._card;
    this._requestId = _prepaidRequestInfo?.requestId;
    setTimeout(() => {
      this.emitCardValue();
    }, 0);
  }

  public emitCardValue() {
    this.cardValueEmitted.emit(this._card);
  }

  public selectedAccount(productType: string) {
    this.globalService.setOriginAccountType(productType);
    if (productType === PRODUCT_TYPES.PAYPAL) {
      this._PaypaylIsClicked = true;
      this._PrepaidIsClicked = false;
    } else {
      this._PaypaylIsClicked = false;
      this._PrepaidIsClicked = true;
    }
  }

  validateShowCard(prepaidInfo: any, _prepaidRequestInfo: any) {
    if (!(prepaidInfo && _prepaidRequestInfo?.requestId)) {
      return false;
    }
    return true;
  }

  public handleChildEvent(event: any) {
    this.hasBalance = event;
  }
}
