import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewDocumentComponent } from '@components/attachment/preview-document/preview-document.component';
import { KipoService } from '@core/kipo.service';
import { clientTypesEnum } from '@enums/identificationTypes.enum';
import { BaseComponent, BootService } from '@impesa/ngx-core';
import {
  destinationAccount,
  destinationAccountResponse,
  updateDestinationAccount,
} from '@models/destination-account/dtos/destination-account';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import { issuerAccount } from '../../models/issuer-account';

@Component({
  selector: 'app-destination-account',
  templateUrl: './destination-account.component.html',
  styleUrls: ['./destination-account.component.scss'],
})
export class DestinationAccountComponent
  extends BaseComponent
  implements OnInit
{
  @Input() user!: any;
  @Output() changeDisplayModal: any;
  destinationAccount = new destinationAccount();
  updatDestinationAccount = new updateDestinationAccount();

  private attachmentChange: boolean = false;

  public accountForm!: UntypedFormGroup;
  public issuers: issuerAccount[] = [];
  public accountTypes: any;
  public template: any;
  public render: boolean = true;
  public isLoading: boolean = false;
  public patternMask!: string;
  public showAddAttachment: boolean = false;
  public isMobile = false;
  public attachment: any;

  constructor(
    boot: BootService,
    private readonly globalService: GlobalVarsService,
    private readonly kipoService: KipoService,
    public dialogRef: MatDialogRef<DestinationAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(boot);
    this.changeDisplayModal = new EventEmitter();
  }

  ngOnInit(): void {
    this.accountForm = new UntypedFormGroup({});

    this.kipoService.getCountryContent().subscribe({
      next: (template: any) => {
        this.template = template;
        for (const control of template.destinationAccount) {
          if (control.id === 'identification') {
            this.accountForm.addControl(
              control.id,
              new UntypedFormControl(
                {
                  value: this.data?.profile?.identificationNumber,
                  disabled: false,
                },
                Validators.required
              )
            );
          } else {
            this.accountForm.addControl(
              control.id,
              new UntypedFormControl(
                { value: '', disabled: false },
                Validators.required
              )
            );
          }
          this.patternMask = this.template.destinationAccount.find(
            (field: { id: string }) => field.id === 'accountNumber'
          )?.patternMask;
        }
      },
      error: (err: any) => {
        this.globalService.errorMessage(err.error.message || err.message);
      },
    });

    forkJoin([
      this.kipoService.getIssuers(),
      this.kipoService.getAccountType(),
    ]).subscribe(([issuers, accountTypes]) => {
      this.accountTypes = accountTypes;

      if (this.data?.account?.typeId) {
        const accountType = this.accountTypes.filter(
          (accountType: any) => accountType.id === this.data?.account?.typeId
        );
        this.accountForm.get('accountType')?.patchValue(accountType[0].id);
      } else {
        this.accountForm.get('accountType')?.patchValue(accountTypes[0].id);
      }

      this.issuers = issuers;
      if (this.data?.account?.issuerId) {
        const issuer = this.issuers.filter(
          issuer => issuer.code === this.data?.account?.issuerId
        );
        this.accountForm.get('destinationIssuers')?.patchValue(issuer[0]);
        this.validateIssuer();
      } else {
        this.accountForm.get('destinationIssuers')?.patchValue(this.issuers[0]);
        this.validateIssuer();
      }

      if (this.data?.account?.issuerId) {
        this.accountForm.patchValue(this.data?.account);
        this.accountForm
          .get('accountNumber')
          ?.patchValue(this.data?.account?.account);
        this.accountForm
          .get('accountType')
          ?.patchValue(this.data?.account?.typeId);
      }
      this.render = false;
    });
    this.isMobile = window.screen.width <= 768;
  }

  public showPreviewDocument(data: any, fileName: string) {
    this.dialogService.dialog.open(PreviewDocumentComponent, {
      width: '100%',
      height: '100%',
      data: {
        file: data,
        fileName: fileName,
      },
    });
  }

  cancel() {
    this.accountForm.reset();
    this.accountForm.patchValue({
      identification: this.data?.profile?.identificationNumber,
    });
    this.showAddAttachment = false;
    this.accountForm.removeControl('attachment');
    this.changeDisplayModal.emit();
    this.dialogRef.close();
  }

  saveData() {
    this.destinationAccount = {
      account: this.accountForm.get('accountNumber')?.value.toUpperCase(),
      alias: this.accountForm.get('alias')?.value,
      typeId: this.accountForm.get('accountType')?.value,
      issuerId: this.getIssuerId(),
      status: this.data?.account?.status,
      userId: this.data?.profile?.userId,
    };

    if (this.data?.account?.issuerId) {
      this.updatDestinationAccount['accountId'] = this.data?.account?.accountId;
      this.updatDestinationAccount.account = this.destinationAccount.account;
      this.updatDestinationAccount.alias = this.destinationAccount.alias;
      this.updatDestinationAccount.typeId = this.destinationAccount.typeId;
      this.updatDestinationAccount.issuerId = this.destinationAccount.issuerId;
      this.updatDestinationAccount.status = this.destinationAccount.status;
      this.updatDestinationAccount.userId = this.destinationAccount.userId;

      this.kipoService
        .updateDestinationAccount(this.updatDestinationAccount)
        .subscribe((res: any) => {
          if (this.attachmentChange) {
            this.uploadAttachment(res);
          } else {
            this.cancel();
          }
        });
      return;
    }
    if (this.accountForm.valid) {
      this.isLoading = true;
      this.kipoService.addDestinationAccount(this.destinationAccount).subscribe(
        response => {
          this.uploadAttachment(response);

          this.cancel();
        },
        err => {
          this.globalService.errorMessage(err.error.message || err.message);
          this.isLoading = false;
        }
      );
    } else {
      return this.globalService.errorMessage(
        this.translate('additionalInfoLegal.requiredAlert')
      );
    }
  }

  getIssuerId() {
    if (this.accountForm.get('destinationIssuers')?.value.code) {
      return this.accountForm.get('destinationIssuers')?.value.code;
    }
    return this.accountForm.get('accountNumber')?.value.substr(4, 4);
  }

  uploadAttachment(response: destinationAccountResponse) {
    const attachment = this.accountForm.get('attachment')?.value;
    if (attachment || this.attachmentChange) {
      this.kipoService
        .addDestinationAccountAttachement(
          response?.accountId || this.data?.account?.accountId,
          'DA_DOCUMENT',
          attachment
        )
        .subscribe({
          next: () => {
            this.cancel();
          },
          error: (err: any) => {
            this.globalService.errorMessage(err.error.message || err.message);
          },
        });
    }
  }

  public getAttachment() {
    this.kipoService.getAttachment(this.data.account.filePath).subscribe({
      next: (res: any) => {
        this.showPreviewDocument(res, this.data.account.fileName);
      },
      error: () => {},
    });
  }

  validateIssuer(): void {
    const issuer = this.accountForm.get('destinationIssuers')?.value;
    if (!issuer) return;
    if (this.data?.profile?.clientType === clientTypesEnum.PERSON) {
      this.showAddAttachment = issuer?.personAddAttachment;
    } else {
      this.showAddAttachment = issuer?.businessAddAttachment;
    }
    if (!this.showAddAttachment) {
      this.accountForm.removeControl('attachment');
    }
  }

  public attachmentChanged(event: any) {
    this.attachmentChange = event;
  }

  isValidAccount() {
    if (!this.template.destinationAccountValidators.useValidator) {
      return true;
    }
    const regx = new RegExp(
      this.template.destinationAccountValidators.regexValidator
    );
    const validAccount = regx.test(
      this.accountForm.get('accountNumber')?.value
    );
    return validAccount;
  }
}
