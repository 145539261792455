<div class="header wave">
  <div class="col-md-12 text-center">
    <h3 class="header-title">
      {{ 'withdraw.confirm.panelTitle' | translate }}
    </h3>
  </div>
</div>

<div class="content-modal">
  <dl class="row">
    <dt class="col-sm-4">
      {{ 'withdraw.confirm.panelLabelSourceAccount' | translate }}
    </dt>
    <dd class="col-sm-8 text-break">
      {{ transaction.paypalAccount }}
    </dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-6">
      {{ 'withdraw.confirm.panelLabelTargetAccount' | translate }}
    </dt>
    <dd class="col-sm-6 word-wrap">
      {{ transaction.sinpeAccount }}
    </dd>
  </dl>

  <dl class="row">
    <dt class="col-sm-6">
      {{ 'withdraw.confirm.panelLabelAmount' | translate }}
    </dt>
    <dd class="col-sm-6">
      {{ transaction?.amount?.amount.replace(',', '.') | currency: 'USD' }}
    </dd>
  </dl>

  <dl class="row">
    <dt class="col-sm-6">
      {{ 'withdraw.confirm.panelLabelFee' | translate }}
    </dt>
    <dd class="col-sm-6">
      {{ accumulateFeeValue | currency: 'USD' }}
    </dd>
  </dl>
  <dl class="row" *ngIf="transaction.amount?.ITBMS || transaction.amount?.IVA">
    <dt class="col-sm-6">
      ({{ 'transactions.labelCountryFee' | translate }} {{ IVA }}%)
    </dt>
    <dd class="col-sm-6">
      {{ transaction.amount?.ITBMS || transaction.amount?.IVA }}
    </dd>
  </dl>
  <dl class="row">
    <dt class="col-sm-6">
      {{ 'withdraw.confirm.panelLabelNetAmoutn' | translate }}
    </dt>
    <dd class="col-sm-6">
      {{ transaction.amount?.finalAmount }}
    </dd>
  </dl>

  <dl class="row border-bottom-0 pb-0 mb-0">
    <dt class="col-sm-6">
      {{ 'withdraw.confirm.panelLabelDate' | translate }}
    </dt>
    <dd class="col-sm-6">
      {{ transaction.date | date: 'd MMM yyyy hh:mm z' }}
    </dd>
  </dl>

  <p class="row border-bottom-0 pt-4 pb-0 mb-0" *ngIf="transaction.realTime">
    {{ 'withdraw.panelLabelThreeRealTime' | translate }}
  </p>
</div>

<div class="pt-3 text-center">
  <button
    id="btnBack"
    class="btn btn-secondary m-3 withdrawConfirm-btnBack"
    (click)="callParent($event)">
    {{ 'withdraw.confirm.panelButtonBack' | translate }}
  </button>
  <button
    id="btnNext"
    class="btn btn-secondary m-3 withdrawConfirm-btnNext"
    (click)="callParent($event)"
    [disabled]="isLoading">
    {{ 'withdraw.confirm.panelButtonConfirm' | translate }}
  </button>
</div>
