import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-complete-kyc',
  templateUrl: './complete-kyc.component.html',
  styleUrls: ['./complete-kyc.component.scss'],
})
export class CompleteKycComponent {
  @Input() viewLabel: boolean = false;
}
