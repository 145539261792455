<form [formGroup]="calculationForm">
  <table class="table">
    <th></th>
    <tbody>
      <tr id="kipo-rowsCalculadora">
        <td id="cellCalc" class="label">
          {{ 'withdraw.panelLabelAmount' | translate }}
        </td>
        <td id="tablefooter" class="padding">
          <div style="display: flex">
            <div>
              <input
                class="d-lg-flex amount calculator-form-amount"
                type="text"
                formControlName="amount"
                appAllowOnlyNumber
                [allowDecimalAndComma]="true"
                [readOnly]="isAmountEnable"
                [maxlength]="template.calculator.amountMaxLength" />
            </div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let fee of template.calculator.fees">
        <td [hidden]="fee.hide" class="label padding">
          {{ fee.label }}
        </td>
        <td [hidden]="fee.hide" class="padding">
          <div style="display: flex">
            <div class="input">
              <input
                class="fees calculator-form-fees"
                type="text"
                [formControlName]="fee.id" />
            </div>
          </div>
        </td>
      </tr>
      <tr *ngFor="let tax of template.calculator.taxes">
        <td class="label padding" *ngIf="template.calculator.taxes[0]?.amount">
          {{ tax.label }} <span>{{ tax.labelAmount }}</span>
          <br />
          <span class="itemlabel">{{ tax.labelTax }}</span>
        </td>
        <td class="padding" *ngIf="template.calculator.taxes[0]?.amount">
          <div style="display: flex">
            <div class="input">
              <input
                class="d-lg-flex fees calculator-form-tax"
                type="text"
                [formControlName]="tax.id" />
            </div>
          </div>
        </td>
      </tr>
      <tr class="row-line">
        <td id="cellCalcborder" class="label">
          {{ 'withdraw.amountDebit' | translate }}
        </td>
        <td class="text-center padding" id="tablefooter">
          <div style="display: flex">
            <div class="input">
              <input
                class="d-xl-flex justify-content-xl-center center-text fees calculator-form-finalAmount"
                type="text"
                formControlName="finalAmount"
                id="finalAmount" />
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>
