<div class="inactive-modal">
  <h5 class="pb-3">{{ 'withdraw.inactiveDisclaimer' | translate }}</h5>
  <div class="button">
    <button (click)="close()" mat-raised-button class="cancel">
      {{ 'withdraw.notInactive' | translate }}
    </button>
    <button (click)="onButtonClick()" mat-raised-button class="inactivate">
      {{ 'withdraw.inactive' | translate }}
    </button>
  </div>
</div>
