import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgxMaterialModule } from '@impesa/ngx-core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-kyc-alert-rej',
  standalone: true,
  imports: [CommonModule, NgxMaterialModule, TranslateModule],
  templateUrl: './kyc-alert-rej.component.html',
  styleUrls: ['./kyc-alert-rej.component.scss'],
})
export class KycAlertREJComponent {
  country = localStorage.getItem('country');
}
