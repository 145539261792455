import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { KipoService } from '@core/kipo.service';
import { BaseComponent, BootService } from '@impesa/ngx-core';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent extends BaseComponent implements OnInit {
  public _listRawTransactions: any[] = [];
  public _transactions: any[] = [];

  public _fromDate!: Date;
  public _toDate!: Date;
  public _filterCriteria: string = '';

  @ViewChild('datePickerStart', { static: false })
  _datePickerStart!: MatDatepicker<Date>;
  @ViewChild('datePickerEnd', { static: false })
  _datePickerEnd!: MatDatepicker<Date>;

  @ViewChild('inputStartDate', { static: false }) inputStartDate!: MatInput;
  @ViewChild('inputEndDate', { static: false }) _inputEndDate!: MatInput;

  @ViewChild('transactionTable') transactionTable!: ElementRef;

  public range: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    boot: BootService,
    private readonly kipoService: KipoService,
    private readonly globalService: GlobalVarsService
  ) {
    super(boot);
  }

  ngOnInit() {
    this._fromDate = this.getFromDate();
    this._toDate = new Date(new Date().getTime());

    this.range = this.formBuilder.group({
      startDate: new UntypedFormControl(this._fromDate),
      endDate: new UntypedFormControl(this._toDate),
    });
    this.filterByDates();
  }

  getFromDate() {
    const date: Date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  loadTransactions() {
    this._transactions.forEach((transaction: any) => {
      transaction.commission = 0;
      transaction.ITBMS = 0;
      transaction.fee?.feeDetail.map((fee: any) => {
        if (fee.source === 'amount') {
          transaction.commission += fee.total;
        }
        if (fee.source === 'commission') {
          transaction.ITBMS += fee.total;
        }
      });
      transaction.commission = this.getRoundNumber(transaction.commission);
      transaction.ITBMS = this.getRoundNumber(transaction.ITBMS);

      transaction.total = this.getRoundNumber(
        transaction.amount + transaction.commission + transaction.ITBMS
      );
    });
  }

  filTransactions(event: any) {
    let filterCriteria = event.target.value;
    const tempLisTransactions: any[] = [];

    if (filterCriteria) {
      filterCriteria = filterCriteria.toLowerCase();
    }
    this._listRawTransactions.forEach(itemTransaction => {
      if (
        itemTransaction.transactionId
          .toString()
          .toLowerCase()
          .includes(filterCriteria) ||
        itemTransaction?.ref2
          ?.toString()
          .toLowerCase()
          .includes(filterCriteria) ||
        itemTransaction.createAt
          .toString()
          .toLowerCase()
          .includes(filterCriteria) ||
        itemTransaction.amount
          .toString()
          .toLowerCase()
          .includes(filterCriteria) ||
        itemTransaction.type
          .toString()
          .toLowerCase()
          .includes(filterCriteria) ||
        itemTransaction.title
          .toString()
          .toLowerCase()
          .includes(filterCriteria) ||
        itemTransaction.status
          .toString()
          .toLowerCase()
          .includes(filterCriteria) ||
        itemTransaction.total.toString().toLowerCase().includes(filterCriteria)
      ) {
        tempLisTransactions.push(itemTransaction);
      }
    });

    this._transactions = tempLisTransactions;
    this.loadTransactions();
  }

  public filterByDates() {
    this._filterCriteria = '';

    const starDate = this.range
      .get('startDate')
      ?.value?.toISOString()
      .split('T')[0];
    const endDate = this.range
      .get('endDate')
      ?.value?.toISOString()
      .split('T')[0];

    if (starDate && endDate) {
      this.kipoService.getTransactionsbyRange(starDate, endDate).subscribe(
        (resultTransactions: any) => {
          if (resultTransactions) {
            this._listRawTransactions = resultTransactions.transactions;
            this._transactions = resultTransactions.transactions;
            this.loadTransactions();
          } else {
            this._listRawTransactions = [];
            this._transactions = [];
          }
        },
        err => {
          this.globalService.errorMessage(err.error.message || err.message);
        }
      );
    }
  }

  public async exportToPdf() {
    const doc = new jsPDF('p', 'mm', 'a4');
    const canvas = await html2canvas(this.transactionTable.nativeElement, {
      onclone: clonedDoc => {
        const originalStyle = window.getComputedStyle(
          this.transactionTable.nativeElement
        );
        const clonedElement = clonedDoc.getElementById(
          this.transactionTable.nativeElement.id
        );
        if (clonedElement) {
          clonedElement.style.cssText = originalStyle.cssText;
        }
      },
    });
    const imgData = canvas.toDataURL('image/png');
    const pageWidth = 210;
    const pageHeight = 297;
    let imgWidth = (canvas.width * 25.4) / 96;
    let imgHeight = (canvas.height * 25.4) / 96;
    const scale = pageWidth / imgWidth;
    imgWidth *= scale;
    imgHeight *= scale;
    const pages = Math.ceil(imgHeight / pageHeight);
    for (let i = 0; i < pages; i++) {
      if (i > 0) {
        doc.addPage();
      }
      const srcY = i * pageHeight;
      doc.addImage(imgData, 'PNG', 0, -srcY, imgWidth, imgHeight);
    }
    doc.save(`kipo-transactions-${new Date().toISOString()}.pdf`);
  }

  public exportToCSV() {
    const csvDocData = this._transactions.map((element: any) => {
      const date = new Date(element.createAt);
      const newElement = {
        createAt: `${[
          date.getDate(),
          date.getMonth() + 1,
          date.getFullYear(),
        ].join('/')} ${date.toLocaleTimeString()}`,
        detail: `${[
          this.translate(`transactions.${element.productType}`),
          element.account,
          element.ref2,
        ].join('\n')}`,
        status: element.title,
        amount: `$${element.amount}`,
        commission: `$${element.commission}`,
        ITBMS: `$${element.ITBMS}`,
        total: `$${element.total}`,
      };
      return newElement;
    });

    const fileName = `kipo-transactions-${new Date().toISOString()}`;
    const options = {
      headers: [
        'Fecha',
        'Detalle',
        'Estado',
        'Monto a transferir',
        'Comisión',
        'ITBMS',
        'Total debitado',
      ],
      title: fileName,
    };
    const angularCsv = new AngularCsv(csvDocData, fileName, options);
    return angularCsv;
  }

  private getRoundNumber(amount: any) {
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }
    return Math.round(amount * 100 + Number.EPSILON) / 100;
  }
}
