<div class="position-relative">
  <div
    mat-dialog-title
    class="dialog-title mb-1 d-flex justify-content-end align-items-end">
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content [ngClass]="isMobile ? 'max-h-mobile' : ''">
    <div class="prepaid-popup-desktop" *ngIf="!showForm && !isMobile">
      <img
        class="d-inline-block align-top img-producto"
        src="/assets/CRI/prepaid/kipo-popup-desktop.png"
        alt="prepaid-card-img" />
      <h1>{{ 'prepaidCard.popUpDescription' | translate }}</h1>

      <h3>{{ 'prepaidCard.popUpDescription2' | translate }}</h3>

      <h4>{{ 'prepaidCard.popUpDescription3' | translate }}</h4>

      <h5>{{ 'prepaidCard.popUpDescription4' | translate }}</h5>

      <button class="btn-background button-text" (click)="addPrepaidCard()">
        {{ 'prepaidCard.popUpBtn' | translate }}
      </button>
      <span
        aria-hidden="true"
        (click)="dontShowPopup()"
        (keypress)="dontShowPopup()">
        {{ 'prepaidCard.dontShowPopUp' | translate }}</span
      >
    </div>
    <div class="prepaid-popup-mobile" *ngIf="!showForm && isMobile">
      <img
        class="d-inline-block align-top img-producto"
        src="/assets/CRI/prepaid/kipo-pop-up-mobile.svg"
        alt="prepaid-card-img" />
      <div class="div-top">
        <h1>{{ 'prepaidCard.popUpDescription' | translate }}</h1>

        <h3>{{ 'prepaidCard.popUpDescription2' | translate }}</h3>

        <h4>{{ 'prepaidCard.popUpDescription3' | translate }}</h4>
      </div>
      <div class="div-bottom">
        <h5>{{ 'prepaidCard.popUpDescriptionMobile1' | translate }}</h5>

        <h5>{{ 'prepaidCard.popUpDescriptionMobile2' | translate }}</h5>

        <h5>{{ 'prepaidCard.popUpDescriptionMobile3' | translate }}</h5>
      </div>

      <button class="btn-background button-text" (click)="addPrepaidCard()">
        {{ 'prepaidCard.popUpBtn' | translate }}
      </button>
      <div
        class="alert-text"
        aria-hidden="true"
        (click)="dontShowPopup()"
        (keypress)="dontShowPopup()">
        {{ 'prepaidCard.dontShowPopUp' | translate }}
      </div>
    </div>
    <div *ngIf="showForm">
      <app-prepaid-card-form></app-prepaid-card-form>
    </div>
  </mat-dialog-content>
</div>
