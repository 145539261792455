import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { KipoService } from '@core/kipo.service';
import { PaypalAccount } from '@models/paypal-accounts';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';

import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
  @Input() _paypalBalance!: any;
  @Input() _paypalAccount!: PaypalAccount;
  @Input() _formLoadError!: boolean;
  @Input() _withdrawForm!: UntypedFormGroup;
  @Input() _card!: any;
  @Input() hasBalance!: any;

  public userProfile!: any;
  public showRequest!: boolean;
  public requestId!: any;

  constructor(
    private readonly kipoFService: KipoService,
    private readonly globalService: GlobalVarsService
  ) {}

  ngOnInit() {
    this.userProfile = this.globalService.getProfile();
    this.showRequest = this.globalService.getPrepaidRequestInfo()?.showRequest;
    this.requestId = this.userProfile.requestId;
  }

  doPaypalLogin() {
    this.kipoFService.getCountryContent().subscribe({
      next: data => {
        const paypal = environment.production
          ? data.paypal?.prod
          : data.paypal?.stag;

        const params = {
          flowEntry: 'CWPP_large',
          response_type: environment.paypal.responseType,
          scope: environment.paypal.scope,
          redirect_uri: `${window.location.origin}${paypal?.callback}`,
          client_id: paypal.clientId,
          nonce: 27066938,
          newUI: 'Y',
        } as any;

        const queryString = Object.keys(params)
          .map((key: string) => `${key}=${params[key]}`)
          .join('&');
        window.open(`${environment.paypal.host}?${queryString}`, '_self');
      },
      error: err => {
        this.globalService.errorMessage(err.error.message || err.message);
      },
    });
  }
}
