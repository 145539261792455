import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent, BootService } from '@impesa/ngx-core';

export interface FileDetail {
  URL: any;
  showPdf: string;
}

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss'],
})
export class PreviewDocumentComponent extends BaseComponent {
  @Input() url: any;
  @Input() showPdf: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public fileDetail: FileDetail,
    boot: BootService
  ) {
    super(boot);
  }
}
