import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appNgClickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside: EventEmitter<any>;
  @Input() showNotifications: any;
  constructor(private readonly elementRef: ElementRef) {
    this.clickOutside = new EventEmitter<any>();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any) {
    const isInsideClicked =
      this.elementRef.nativeElement.contains(targetElement) &&
      this.showNotifications;

    const isInsideNotifyIco = targetElement?.classList.contains('notify-ico');
    if (!isInsideClicked && !isInsideNotifyIco) {
      this.clickOutside.emit(null);
    }
  }
}
