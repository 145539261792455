<div class="col-12 panel-w">
  <p class="text-center title">
    {{ 'prepaidCard.userGuide.requestInProcess' | translate }}
  </p>
  <span class="text-center subtitle">
    {{ 'prepaidCard.userGuide.subTtitle' | translate }}
  </span>
  <br />
  <br />
  <span class="subtitle">
    {{ 'prepaidCard.userGuide.subTitle2' | translate }}
  </span>
  <br />
  <span> {{ 'prepaidCard.userGuide.prepaidInfo1' | translate }}</span>
  <br />
  <span> {{ 'prepaidCard.userGuide.prepaidInfo2' | translate }} </span>
  <br />
  <br />
  <ul>
    <li>
      <span class="font-weight-bold">
        {{ 'prepaidCard.userGuide.realTimeInfo' | translate }}
      </span>
      {{ 'prepaidCard.userGuide.realTimeInfo2' | translate }}
    </li>

    <li>
      <span class="font-weight-bold">
        {{ 'prepaidCard.userGuide.entityInfo' | translate }}
      </span>
      {{ 'prepaidCard.userGuide.entityInfo2' | translate }}
    </li>
    <li>
      <span class="font-weight-bold">
        {{ 'prepaidCard.userGuide.rechargeCardInfo' | translate }}
      </span>
      {{ 'prepaidCard.userGuide.rechargeCardInfo2' | translate }}
    </li>
  </ul>

  <span> {{ 'prepaidCard.userGuide.cardInfo' | translate }}</span>
  <br />
  <span> {{ 'prepaidCard.userGuide.acknowledgements' | translate }} </span>
</div>
