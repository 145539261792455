import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgxMaterialModule } from '@impesa/ngx-core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-kyc-alert-rev',
  standalone: true,
  imports: [CommonModule, NgxMaterialModule, TranslateModule],
  templateUrl: './kyc-alert-rev.component.html',
  styleUrls: ['./kyc-alert-rev.component.scss'],
})
export class KycAlertREVComponent {}
