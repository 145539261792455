import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KipoService } from '@core/kipo.service';
import { environment } from '@environment/environment';
import { NgxCoreModule } from '@impesa/ngx-core';
import { ResolveKycService } from '@impesa/ngx-kyc';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { ErrorInterceptor } from './interceptors/error-interceptor.interceptor';
import { PrivateModule } from './layout/private/private.module';
import { MaterialModule } from './material.module';
import { UserInfoResolver } from './resolver/user-info.resolver';

@NgModule({
  declarations: [AppComponent, CalculatorComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PrivateModule,
    NgxCoreModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (translate: TranslateService) => {
        return () => translate.use('es');
      },
      deps: [TranslateService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'APP.CONFIG', useValue: environment },
    { provide: 'APP.LOCALE', useValue: 'es-CR' },
    { provide: 'APP.LOCALE.INIT', useValue: localeInit },
    {
      provide: 'APP.I18N.CONFIG',
      useFactory: (cs: KipoService) => {
        const country = cs.getCountry();
        return {
          prefix: `assets/i18n/${country}/`,
          default: 'es',
        };
      },
      deps: [KipoService],
    },
    KipoService,
    GlobalVarsService,
    ResolveKycService,
    UserInfoResolver,
    Meta,
  ],
  exports: [NgxCoreModule],
  bootstrap: [AppComponent],
})
export class AppModule {}

function localeInit(locale: string): Promise<unknown> {
  return import(
    /* webpackExclude: /\.d\.ts$/ */
    /* webpackMode: "lazy-once" */
    /* webpackChunkName: "i18n-extra" */
    /* webpackInclude: /(es-CR|es-PA)\.mjs$/ */
    `../../node_modules/@angular/common/locales/${locale}.mjs`
  );
}
