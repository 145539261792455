<div class="pb-3">
  <mat-icon class="logo-icon">report</mat-icon>
  <span class="font-weight-bold text h6">
    {{ 'withdraw.alertCompleteInformation' | translate }}
  </span>
</div>

<button
  class="btn btn-secondary btn-block w-auto mx-auto alert-completeKycBtn"
  type="button"
  [routerLink]="['/app/profile']"
  [queryParams]="{ completeKyc: true }">
  {{ 'withdraw.completeInformation' | translate }}
</button>
