<div class="container pt-3">
  <div class="panel">
    <div class="col-md-12">
      <div #tableTransactions>
        <div class="row pt-3">
          <div class="col-md-4 col-sm-12">
            <mat-form-field appearance="outline" class="date">
              <mat-label>{{
                'transactions.labelDateTitle' | translate
              }}</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="startDate"
                  placeholder="{{ 'transactions.labelFrom' | translate }}"
                  #dateRangeStart />
                <input
                  matEndDate
                  formControlName="endDate"
                  placeholder="{{ 'transactions.labelTo' | translate }}"
                  #dateRangeEnd
                  (dateChange)="filterByDates()" />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="input-group">
              <input
                type="text"
                [(ngModel)]="_filterCriteria"
                class="form-control transactionHistory-findMovements input-text"
                placeholder="{{
                  'transactions.labelFindTransactions' | translate
                }}"
                (keyup)="filTransactions($event)" />
            </div>
          </div>
          <div class="col-md-2 col-sm-12">
            <button
              class="reverse-button input-text"
              mat-stroked-button
              mat-button
              [matMenuTriggerFor]="menu"
              [disabled]="!(_transactions && _transactions.length > 0)">
              {{ 'transactions.labelActions' | translate }}

              <mat-icon
                class="ml-2"
                aria-hidden="false"
                aria-label="arrow_drop_down icon">
                arrow_drop_down
              </mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="exportToPdf()">
                {{ 'transactions.labelExportPdf' | translate }}
              </button>
              <button mat-menu-item (click)="exportToCSV()">
                {{ 'transactions.labelExportCsv' | translate }}
              </button>
            </mat-menu>
          </div>
        </div>

        <div id="transactionTable" class="row pt-3">
          <div class="panel-default" style="width: 100%">
            <div class="panel-body table-responsive">
              <table
                #transactionTable
                class="table table-condensed table-striped"
                style="width: 100%"
                aria-label="Transaction History Table">
                <thead class="table-head-group">
                  <th
                    class="table-cell table-head-r text-center"
                    data-sortable="true">
                    {{ 'transactions.labelDate' | translate }}
                  </th>
                  <th class="table-cell text-center" data-sortable="true">
                    {{ 'transactions.labelDetail' | translate }}
                  </th>
                  <th class="table-cell text-center" data-sortable="true">
                    {{ 'transactions.labelStatus' | translate }}
                  </th>
                  <th
                    class="table-cell table-head-l text-center"
                    data-sortable="true">
                    {{ 'transactions.labelTransferAmount' | translate }}
                  </th>
                  <th
                    class="table-cell table-head-l text-center"
                    data-sortable="true">
                    {{ 'transactions.labelFee' | translate }}
                  </th>
                  <th
                    class="table-cell table-head-l text-center"
                    data-sortable="true">
                    {{ 'transactions.labelCountryFee' | translate }}
                  </th>
                  <th
                    class="table-cell table-head-l text-center"
                    data-sortable="true">
                    {{ 'transactions.labelTotalAmount' | translate }}
                  </th>
                </thead>
                <tbody>
                  <tr *ngFor="let char of _transactions" class="row-index">
                    <td class="text-center dateColumn">
                      {{ char.createAt | date: 'd MMM yyyy' }}
                    </td>
                    <td class="detailColumn">
                      <p class="rowText mb-0">
                        {{ 'transactions.' + char.productType | translate }}
                      </p>
                      <span class="rowHeader">{{ char.account }}</span>
                      <p class="rowSubText mb-0">
                        {{ char.ref2 }}
                      </p>
                      <p class="rowSubText mb-0">
                        {{ char.issuerName }}
                      </p>
                    </td>
                    <td
                      [ngClass]="{
                        'transac-paid': char.status === 'APLI',
                      }"
                      class="text-center targetColumn">
                      {{ char.title }}
                    </td>
                    <td class="text-center">
                      {{ char.amount | currency: 'USD' }}
                    </td>
                    <td class="text-center">
                      {{ char.commission | currency: 'USD' }}
                    </td>
                    <td class="text-center">
                      {{ char.ITBMS | currency: 'USD' }}
                    </td>
                    <td class="text-center amountColumn">
                      {{
                        char.amount + char.commission + char.ITBMS
                          | currency: 'USD'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="panel">
        <p style="text-align: center">
          <small>
            {{ 'withdraw.disclaimerContact' | translate }}
            <a [href]="'withdraw.disclaimerContactLink' | translate | safeUrl">
              {{ 'withdraw.disclaimerContact2' | translate }}
            </a>
          </small>
        </p>
      </div>
    </div>
  </div>
</div>
