<div
  *ngIf="!_file"
  class="container w-100 {{
    this.isValidAttachment ? '' : 'invalid-attachment-input'
  }}"
  appDnd
  (fileDropped)="onFileDropped($event)">
  <input
    accept="{{ accept }}"
    type="file"
    #fileDropRef
    id="fileDropRef"
    class="upload-attachment-input"
    multiple="false"
    required
    (change)="fileBrowseHandler($event)" />
  <mat-icon aria-hidden="false" aria-label="icon"> backup </mat-icon>
  <small
    >Arrastra el archivo aquí o selecciona el archivo
    <a href="#">(link)</a></small
  >
  <small class="{{ this.isValidAttachment ? 'hide' : '' }}"
    >{{ 'additionalInfo.attachmentRequired' | translate }}
  </small>
</div>

<div class="files-list" *ngIf="_file">
  <div class="single-file">
    <mat-icon
      *ngIf="!account"
      aria-hidden="false"
      aria-label="file logo"
      class="icon mr-2"
      >description</mat-icon
    >

    <div class="form-group mb-0 pr-2" *ngIf="account">
      <app-view-attachment
        [attachment]="account?.filePath"
        [fileName]="account?.fileName"></app-view-attachment>
    </div>
    <div class="info">
      <h4 class="name mb-2" *ngIf="attachmentName">
        {{ attachmentName }}
      </h4>
      <h4 class="name" *ngIf="_file?.name">
        {{ _file?.name }}
      </h4>
      <p class="size" *ngIf="_file?.size">
        {{ formatBytes(_file?.size) }}
      </p>
      <app-progress [progress]="_file?.progress"></app-progress>
    </div>

    <button
      [disabled]="disable"
      mat-icon-button
      color="warn"
      class="upload-delete-attachment"
      aria-label="delete attachment"
      (click)="deleteFile()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>

<mat-error *ngIf="attachValidate"> {{ attachValidate }} </mat-error>
