import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';

@Component({
  selector: 'app-nav-notificator',
  templateUrl: './nav-notificator.component.html',
  styleUrls: ['./nav-notificator.component.scss'],
})
export class NavNotificatorComponent {
  @Input() _notifications!: any[];
  @Output() readNotifications: EventEmitter<any> = new EventEmitter();

  constructor(
    private readonly kipoService: KipoService,
    private readonly globalService: GlobalVarsService,
    private readonly router: Router
  ) {}

  readNotification(notificationId: any) {
    if (notificationId != '') {
      this.kipoService.readNotification(notificationId).subscribe({
        next: () => {
          this.readNotifications.emit();
        },

        error: err => {
          this.globalService.errorMessage(err.error.message || err.message);
        },
      });
    } else {
      this.readNotifications.emit();
    }
  }

  readUserNotifications() {
    const messages = this._notifications.map(value => value.id);
    if (messages) {
      this.kipoService.readUserNotifications().subscribe({
        next: () => {
          this.readNotifications.emit();
        },
        error: (err: any) => {
          this.globalService.errorMessage(err.error.message || err.message);
        },
      });
    }
  }

  validateMessageType(description: string) {
    if (description.toLowerCase().includes('perfil')) {
      this.router.navigate(['app/profile'], {
        queryParams: {
          completeKyc: true,
        },
      });
    }
  }
}
