import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { RestService, StorageService } from '@impesa/ngx-core';
import { CreateKycPerson } from '@impesa/ngx-kyc/lib/models/kyc/person/request/create-kyc-person.dto';
import { KycPersonDto } from '@impesa/ngx-kyc/lib/models/kyc/person/response/kyc-person.model';
import { Billing, CreateBillingDto } from '@models/billings';
import { ClientType } from '@models/client-type';
import {
  attachmentResponse,
  destinationAccount,
  destinationAccountResponse,
  destinationAccountTypesResponse,
  UpdateDestinationAccountStatusDto,
} from '@models/destination-account/dtos/destination-account';
import { issuerAccount } from '@models/issuer-account';
import { personKyc } from '@models/kyc.model';
import { kycAttachment } from '@models/kyc-attachment.dto';
import { Notices } from '@models/notices.model';
import { Notification } from '@models/notifications';
import { PaypalAccount } from '@models/paypal-accounts';
import { PrepaidCard } from '@models/prepaid-card.model';
import { PrepaidBalance } from '@models/prepaid-card/prepaid-balance.model';
import { CountrySettings } from '@models/settings/country-settings.interface';
import { transactions } from '@models/transactions.model';
import { CreateUserDto } from '@models/users/dtos/create-user.dto';
import { UpdateUserDto } from '@models/users/dtos/update-user.dto';
import { User } from '@models/users/user.model';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { Observable, of, Subject } from 'rxjs';

import * as CRI from '../../assets/CRI/template.json';
import * as GTM from '../../assets/GTM/template.json';
import * as PAN from '../../assets/PAN/template.json';
import { NotificationStatusEnum } from '../enums/notification-status.enum';
import { file } from '../models/destination-account/file.model';

@Injectable()
export class KipoService {
  private readonly AUTH_API = 'auth';
  private readonly KIPO_API = 'kipo';
  private readonly COUNTRY_MAP: any = {
    CRI,
    PAN,
    GTM,
  };
  private readonly userUpdated$ = new Subject<any>();

  constructor(
    private readonly httpService: HttpClient,
    private readonly restService: RestService,
    private readonly storageService: StorageService,
    private readonly globalVarsService: GlobalVarsService
  ) {}

  // #region Country

  public getCountry() {
    return this.storageService.get('country');
  }

  public getCountryContent() {
    const countryCode = this.getCountry();
    return of(this.COUNTRY_MAP[countryCode]);
  }

  public getCountrySettingsByCode(code: string): Observable<CountrySettings> {
    return this.restService.get(this.KIPO_API, `countries/${code}`);
  }

  // #endregion

  // #region User

  public updateUser(userInfo: User) {
    this.userUpdated$.next(userInfo);
  }

  public userUpdated(): Observable<any> {
    return this.userUpdated$.asObservable();
  }

  public getUserData(token?: string): Observable<User> {
    if (token) {
      return this.restService.get(this.KIPO_API, 'users', {
        Authorization: `Bearer ${token}`,
      });
    }
    return this.restService.get(this.KIPO_API, 'users');
  }

  public verifyAccount(token: string): Observable<any> {
    return this.restService.post(this.AUTH_API, `users/verify`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
    });
  }

  public checkIdNumberAvailable(idNumber: string): Observable<boolean> {
    return this.restService.get(this.KIPO_API, `users/exist/${idNumber}`);
  }

  public putUserData(userData: UpdateUserDto): Observable<any> {
    return this.restService.patch(this.KIPO_API, `users`, userData);
  }

  public postUserData(userData: CreateUserDto): Observable<User> {
    return this.restService.post(this.KIPO_API, `users`, userData);
  }

  public putUserDataV2(userData: UpdateUserDto): Observable<any> {
    return this.restService.patch(this.KIPO_API, `v2/users`, userData);
  }

  public postUserDataV2(userData: CreateUserDto): Observable<User> {
    return this.restService.post(this.KIPO_API, `v2/users`, userData);
  }

  public addBillingAccount(billing: CreateBillingDto): Observable<Billing> {
    return this.restService.post(this.KIPO_API, 'billings', billing);
  }

  // #endregion

  // #region KYC

  public getClientTypes(): Observable<ClientType[]> {
    return this.restService.get(
      this.KIPO_API,
      'catalogs/CLIENT_TYPE?status=ACTIV'
    );
    // return this.restService.get(this.KIPO_API, 'users/clientTypes');
  }

  public personKycData(
    userData: CreateKycPerson,
    method: string
  ): Observable<KycPersonDto> {
    if (method == 'Post') {
      return this.restService.post(this.KIPO_API, `users/person`, userData);
    } else {
      return this.restService.patch(this.KIPO_API, `users/person`, userData);
    }
  }

  public savePersonKyc(kyc: personKyc): Observable<any> {
    return this.restService.post(this.KIPO_API, `kyc-persons`, kyc);
  }

  public updatePersonKyc(kyc: personKyc): Observable<any> {
    return this.restService.patch(this.KIPO_API, `kyc-persons`, kyc);
  }

  public addKycPersonAttachement(
    name: string,
    filedata: file
  ): Observable<kycAttachment> {
    const body = new FormData();
    body.append('file', filedata.buffer);

    let params: HttpParams = new HttpParams();
    params = params.set('type', name);

    const api = environment.apis.kipo.url;
    const url = `${api}/attachments/kyc-persons?${params}`;

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpService.post<kycAttachment>(url, body, { headers });
  }

  public deleteAttachment(attachmentId: any): Observable<string> {
    return this.restService.del(this.KIPO_API, `attachments/${attachmentId}`);
  }

  // #endregion

  // #region Notifications

  public getNotifications(
    status: string = NotificationStatusEnum.NEW
  ): Observable<Notification[]> {
    return this.restService.get(
      this.KIPO_API,
      `notifications?status=${status}`
    );
  }

  public readNotification(messageId: string): Observable<number> {
    return this.restService.patch(
      this.KIPO_API,
      `notifications/${messageId}/read`
    );
  }

  public readUserNotifications(): Observable<number> {
    return this.restService.patch(this.KIPO_API, `notifications/read`);
  }

  // #endregion

  // #region Origin accounts

  public postPaypalAccount(code: string): Observable<PaypalAccount> {
    return this.restService.post(this.KIPO_API, 'paypal-accounts', {
      code: code,
    });
  }

  public getUserPayPalAccounts(): Observable<PaypalAccount[]> {
    return this.restService.get(this.KIPO_API, 'paypal-accounts');
  }

  public getUserOriginAccounts(
    productType: string
  ): Observable<PaypalAccount[]> {
    return this.restService.get(
      this.KIPO_API,
      `paypal-accounts/${productType}/productType`
    );
  }

  public getBalance(sessionToken: string): Observable<any> {
    const header = {
      sessiontoken: sessionToken,
    };
    return this.restService.get(
      this.KIPO_API,
      `paypal-accounts/balance`,
      header
    );
  }

  public getCardBalance(productId: string): Observable<PrepaidBalance> {
    return this.restService.get(
      this.KIPO_API,
      `prepaid-accounts/prepaidBalance/${productId}`
    );
  }

  // #endregion

  // #region Destination accounts

  public getAccountType(): Observable<destinationAccountTypesResponse[]> {
    return this.restService.get(this.KIPO_API, 'destination-account-types');
  }

  public getIssuers(): Observable<[issuerAccount]> {
    return this.restService.get(this.KIPO_API, 'issuer-accounts/list');
  }

  public getDestinationAccountsByUser(
    id: string
  ): Observable<destinationAccountResponse[]> {
    return this.restService.get(this.KIPO_API, `destination-accounts/${id}`);
  }

  public addDestinationAccount(
    jsonData: destinationAccount
  ): Observable<destinationAccountResponse> {
    return this.restService.post(
      this.KIPO_API,
      `destination-accounts`,
      jsonData
    );
  }

  public updateDestinationAccount(
    jsonData: destinationAccount
  ): Observable<destinationAccountResponse> {
    return this.restService.patch(
      this.KIPO_API,
      `destination-accounts`,
      jsonData
    );
  }

  public updateDestinationAccountStatus(
    id: string,
    destinationAccountStatus: UpdateDestinationAccountStatusDto
  ): Observable<destinationAccount> {
    return this.restService.patch(
      this.KIPO_API,
      `destination-accounts/inactive/${id}`,
      destinationAccountStatus
    );
  }

  public deleteAccount(jsonData: any): Observable<string> {
    return this.restService.del(
      this.KIPO_API,
      `destination-accounts/${jsonData.accountId}`
    );
  }

  public addDestinationAccountAttachement(
    destinationAccountId: string,
    name: string,
    filedata: file
  ): Observable<attachmentResponse> {
    const body = new FormData();
    body.append('file', filedata.buffer);

    let params: HttpParams = new HttpParams();
    params = params.set('type', name);

    const api = environment.apis.kipo.url;
    const url = `${api}/attachments/destination-accounts/${destinationAccountId}?${params}`;

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpService.post<attachmentResponse>(url, body, { headers });
  }

  getAttachment(filePath: string): Observable<any> {
    const api = environment.apis.kipo.url;
    const _url: string = `${api}/${filePath}`;

    const customHeader = {
      responseType: 'arraybuffer' as 'json',
    };

    return this.httpService.get<any>(_url, customHeader);
  }

  // #endregion

  // #region Withdrawal transactions

  public getKipoLimits(): Observable<[any]> {
    return this.restService.get(this.KIPO_API, `withdrawals/limits`);
  }

  public getKipoFees(): Observable<any> {
    return this.restService.get(this.KIPO_API, `withdrawals/fees`);
  }

  public getTransactionsbyRange(
    toDate: string,
    fromDate: string
  ): Observable<[any]> {
    return this.restService.get(
      this.KIPO_API,
      `withdrawals/transactions?startDate=${toDate}&endDate=${fromDate}`
    );
  }

  public getTransactionsHistory(): Observable<transactions> {
    return this.restService.get(this.KIPO_API, `withdrawals/transactions`);
  }

  public postPaypalWithdraw(jsonData: any): Observable<any> {
    let paypalAccount = this.globalVarsService.getPaypalAccount();

    if (Array.isArray(paypalAccount)) {
      paypalAccount = paypalAccount[0];
    }

    const header = {
      sessiontoken: paypalAccount?.sessionToken || jsonData.sinpeAccountId,
    };
    jsonData = {
      ...jsonData,
      destinationAccountId: jsonData.sinpeAccountId,
    };
    delete jsonData['sinpeAccountId'];
    return this.restService.post(
      this.KIPO_API,
      `withdrawals`,
      jsonData,
      header
    );
  }

  // #endregion

  // #region Prepaid card request

  public requestPrepaidCard(prepaidCard: PrepaidCard): Observable<any> {
    return this.restService.post(
      this.KIPO_API,
      `prepaid-accounts`,
      prepaidCard
    );
  }

  public getPrepaidRequestStatus(): Observable<any> {
    return this.restService.get(
      this.KIPO_API,
      `prepaid-accounts/requestStatus`
    );
  }

  public getPrepaidRequestInfo(): Observable<any> {
    return this.restService.get(this.KIPO_API, `prepaid-accounts`);
  }

  // #endregion

  // #region Miscelaneous services

  public getNotices(): Observable<Notices[]> {
    return this.restService.get(this.KIPO_API, `news`);
  }

  // #endregion
}
