<div
  *ngIf="!_card"
  [ngClass]="{ 'h-100': !_showRequest && !_requestId }"
  class="d-flex flex-column row-gap panel-width col-md-12 pl-0 pr-0">
  <app-balance
    [_paypalAccount]="_payPalAccount"
    [_paypalBalance]="_payPalBalance"
    [_formLoadError]="_formLoadError"
    [_withdrawForm]="_withdrawForm"
    class="h-100"></app-balance>
  <div *ngIf="_showRequest">
    <app-prepaid-card [_prepaidAccount]="_prepaidAccount"> </app-prepaid-card>
  </div>
</div>

<div *ngIf="_card" class="col-sm-12 col-md-12 px-0">
  <div
    class="pointer"
    aria-hidden="true"
    [class.clicked]="_PaypaylIsClicked"
    (click)="selectedAccount('PAYPAL')"
    (keyPress)="selectedAccount('PAYPAL')">
    <app-balance
      [_paypalAccount]="_payPalAccount"
      [_paypalBalance]="_payPalBalance"
      [_formLoadError]="_formLoadError"
      [_withdrawForm]="_withdrawForm"
      [_card]="_card"
      [hasBalance]="hasBalance"></app-balance>
  </div>
  <div
    class="pointer"
    aria-hidden="true"
    [class.clicked]="_PrepaidIsClicked"
    [ngClass]="{ 'no-pointer': !hasBalance }"
    (click)="selectedAccount('PREPAID')"
    (keyPress)="selectedAccount('PREPAID')">
    <app-prepaid-card
      [_prepaidAccount]="_prepaidAccount"
      (hasBalance)="handleChildEvent($event)">
    </app-prepaid-card>
  </div>
</div>
