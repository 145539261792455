<div class="container pt-4" *ngIf="verificationAlert">
  <div class="center">
    <div class="bannerBg">
      <div class="col-md-12 text-center bannerImage">
        <div class="d-flex">
          <div class="mx-auto">
            <img
              routerLink="/"
              src="/assets/kipo/logo-kipo-blanco-1x.png"
              height="auto"
              class="mx-auto btn"
              style="margin-top: 10px"
              alt="logo" />
          </div>
        </div>
      </div>

      <div class="col-md-12 text-center bannerFooter">
        <strong>{{ 'verification.headerTitle' | translate }}</strong>
      </div>
    </div>
    <div class="">
      <div class="col-md-12 text-center bannerWhite" *ngIf="message">
        {{ message }}
      </div>
      <div class="col-md-12 text-center bannerWhite" *ngIf="!message">
        <span>
          {{ 'verification.firstMessage' | translate }}
        </span>
      </div>
    </div>
    <div class="bannerBgFooter">
      <div class="col-md-12 text-center bannerImageFooter">
        <div class="d-flex">
          <div class="mx-auto">
            <img
              routerLink="/"
              src="{{ 'verification.logo1' | translate }}"
              height="60px"
              class="mx-auto btn"
              style="margin-top: 10px"
              alt="logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container pt-4" *ngIf="!verificationAlert">
  <div class="center">
    <div class="col-md-12">
      <div class="mx-auto text-center">
        <mat-spinner class="mx-auto"></mat-spinner>
      </div>
    </div>
  </div>
</div>
