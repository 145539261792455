import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalVarsService {
  private _userObs$ = new Subject();
  private _userBilling$ = new Subject();
  private _listIbansObs$ = new Subject();
  private _listAvailableIbansObs$ = new Subject();
  private _listNotifyObs$ = new Subject();
  private _productTypeObs$ = new Subject();
  private _listNotify: any[] = [];

  constructor(private toastr: ToastrService) {}

  getOriginAccountType(): any {
    return this._productTypeObs$;
  }

  setOriginAccountType(productType: string) {
    this._productTypeObs$.next(productType);
  }

  getNotify(): any {
    return this._listNotifyObs$;
  }

  setNotificationList(listNotify: any[]) {
    this._listNotify = listNotify;
    this._listNotifyObs$.next(this._listNotify);
  }

  addNotification(notify: any) {
    this._listNotify.unshift(notify);
    this._listNotifyObs$.next(this._listNotify);
  }

  getUserProfile(): any {
    return this._userObs$;
  }

  getUserBilling(): any {
    return this._userBilling$;
  }

  getAvailableIbansList(): any {
    return this._listAvailableIbansObs$;
  }

  setAvailableIbans(listIbans: any) {
    this._listAvailableIbansObs$.next(listIbans);
  }

  getIbansAccountList(): any {
    return this._listIbansObs$;
  }

  setIbanAccountsList(listIbans: any) {
    this._listIbansObs$.next(listIbans);
  }

  // Accessing the global access token
  getToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  setProfile(profileData: any) {
    this._userObs$.next(profileData);
    const profileString = JSON.stringify(profileData);
    localStorage.setItem('profile', profileString);
  }

  setPrepaidRequestInfo(prepaidRequest: any) {
    const prepaidRequestString = JSON.stringify(prepaidRequest);
    localStorage.setItem('prepaid-request', prepaidRequestString);
  }

  getPrepaidRequestInfo() {
    const prepaidRequestData = localStorage.getItem('prepaid-request') ?? '{}';
    return JSON.parse(prepaidRequestData);
  }

  setBilling(billingData: any) {
    this._userBilling$.next(billingData);
    const billString = JSON.stringify(billingData);
    localStorage.setItem('billing', billString);
  }

  getProfile() {
    const profileData = localStorage.getItem('profile') || '{}';
    return JSON.parse(profileData);
  }

  getBilling() {
    const billData = localStorage.getItem('billing') || '';
    if (billData) {
      return JSON.parse(billData);
    }
  }

  setPaypalAccount(paypalAccount: any) {
    const paypaAccountString = JSON.stringify(paypalAccount);
    localStorage.setItem('paypal_account', paypaAccountString);
  }

  getPaypalAccount() {
    let paypalAccount = localStorage.getItem('paypal_account') ?? '';

    if (Array.isArray(paypalAccount)) {
      paypalAccount = paypalAccount[0];
    }

    if (paypalAccount) {
      return JSON.parse(paypalAccount);
    }
  }

  setPrepaidAccount(prepaidAccount: any) {
    const prepaidAccountString = JSON.stringify(prepaidAccount);
    localStorage.setItem('prepaid_account', prepaidAccountString);
  }

  getPrepaidAccount() {
    let prepaidAccount = localStorage.getItem('prepaid_account') ?? '';
    if (Array.isArray(prepaidAccount)) {
      prepaidAccount = prepaidAccount[0];
    }

    if (prepaidAccount != 'undefined') {
      return JSON.parse(prepaidAccount);
    }
  }

  setPrepaidBalance(info: any) {
    const infoString = JSON.stringify(info);
    localStorage.setItem('card_balance', infoString);
  }

  getPrepaidBalance() {
    let info = localStorage.getItem('card_balance') ?? '';
    if (Array.isArray(info)) {
      info = info[0];
    }

    if (info) {
      return JSON.parse(info);
    }
  }

  isSessionAvailable(): boolean {
    const expires_at = JSON.parse(localStorage.getItem('expires_at') ?? '');
    if (!expires_at) {
      return false;
    }

    const sessionStatus = new Date().getTime() < expires_at;
    return sessionStatus;
  }

  showSuccessMessage(message: string) {
    this.toastr.success(message);
  }

  showWarningMessage(message: string) {
    this.toastr.warning(message);
  }

  errorMessage(message: string) {
    this.toastr.error(message);
  }

  addCookie(access_token: string) {
    const decodedToken: any = jwt_decode(access_token);
    const myDate = new Date(decodedToken.exp * 1000);
    const kpstCookie = [
      `${environment.cookieName}=${access_token}`,
      `expires=${myDate.toUTCString()}`,
      'path=/',
      'domain=.getkipo.com',
      'secure',
    ].join(';');
    document.cookie = kpstCookie;
  }

  deleteCookie() {
    document.cookie =
      environment.cookieName +
      '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.getkipo.com;secure;';
  }
}
