import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KipoService } from '@core/kipo.service';
import { PrepaidRequestStatusEnum } from '@enums/prepaid-request-status.enum';
import { PRODUCT_TYPES } from '@enums/product-types.enum';
import { BaseComponent, BootService } from '@impesa/ngx-core';
import { PaypalAccount } from '@models/paypal-accounts';
import { IDetails } from '@models/prepaid-card/prepaid-balance.model';
import { IPrepaidRequestInfo } from '@models/prepaid-card/prepaid-request-info.model';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';

import { PrepaidCardAlertComponent } from './prepaid-card-alert/prepaid-card-alert.component';
import { PrepaidCardFormComponent } from './prepaid-card-form/prepaid-card-form.component';

@Component({
  selector: 'app-prepaid-card',
  templateUrl: './prepaid-card.component.html',
  styleUrls: ['./prepaid-card.component.scss'],
})
export class PrepaidCardComponent extends BaseComponent implements OnInit {
  @Input() _prepaidAccount!: PaypalAccount;
  @Output() hasBalance = new EventEmitter<boolean>();

  public _userProfile!: any;

  public showRequest: boolean = false;
  public showStatus: boolean = false;
  public showBalance: boolean = false;
  public _balance!: number;
  public _metadataCard: any;
  public fullName: any;
  public requestId: any;
  public balanceMessage: any;
  public status: any;
  public requestInfo!: IPrepaidRequestInfo;
  public productDateEnable!: boolean;

  constructor(
    boot: BootService,
    public readonly dialog: MatDialog,
    private readonly kipoService: KipoService,
    private readonly globalService: GlobalVarsService
  ) {
    super(boot);
    this._userProfile = this.globalService.getUserProfile();
    this.getOriginAccount();
  }

  ngOnInit(): void {
    this.requestInfo = this.globalService.getPrepaidRequestInfo();
    this.status = this.requestInfo?.status;
    this.getMessage();
  }

  addPrepaidCard() {
    const dialogRef = this.dialog.open(PrepaidCardFormComponent, {
      data: { profile: this._userProfile },
      panelClass: 'custom-modalbox-prepaid',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.requestInfo = this.globalService.getPrepaidRequestInfo();
      if (result === 'formSubmitted' || this.requestInfo?.requestId) {
        this.showRequest = false;
        this.showBalance = false;
        this.showStatus = true;
        this.requestId = this.requestInfo?.requestId;
        this.getMessage();
      }
    });
  }

  private getPrepaidBalance() {
    if (!this._prepaidAccount) return;

    this.showStatus = false;
    this.showRequest = false;
    this.showBalance = false;
    this.kipoService.getCardBalance(this._prepaidAccount.token).subscribe(
      prepaidBalance => {
        this.hasBalance.emit(prepaidBalance?.productDateEnable);
        if (prepaidBalance?.productDateEnable) {
          this.productDateEnable = prepaidBalance.productDateEnable;
          this.showStatus = false;
          this.showBalance = true;
          this._metadataCard = prepaidBalance?.metadata;

          const detailBalance: IDetails[] =
            prepaidBalance?.details?.filter((x: any) => x.currency === 'USD') ||
            [];

          this._balance = detailBalance ? detailBalance[0]?.available : 0;
        } else {
          this.showStatus = true;
        }
        this.globalService.setPrepaidBalance(this._balance);
      },
      (err: any) => {
        if (err) {
          this.showStatus = true;
        }
        this.globalService.errorMessage(err.error.message || err.message);
      }
    );
  }

  private getOriginAccount() {
    this.requestInfo = this.globalService.getPrepaidRequestInfo();
    this.requestId = this.requestInfo?.requestId;

    const showPopup = localStorage.getItem('showPrepaidPopup');

    if (this.requestInfo?.showRequest && !showPopup) {
      this.openModal();
    }

    this.kipoService
      .getUserOriginAccounts(PRODUCT_TYPES.PREPAID)
      .subscribe((prepaidAccounts: PaypalAccount[]) => {
        if (prepaidAccounts) {
          this._prepaidAccount = prepaidAccounts[0];
        }

        this.showRequest = this.requestInfo?.showRequest;

        if (this.requestInfo?.requestId) {
          this.showStatus = true;
        } else {
          this.showStatus = false;
        }
        this.showRequest = !this.showStatus;

        this.getPrepaidBalance();
      });
  }

  getMessage() {
    if (
      !this.productDateEnable &&
      this.status === PrepaidRequestStatusEnum.PROS
    ) {
      this.balanceMessage = this.translate('prepaidCard.sameDateMessage');
    } else if (
      this.status === PrepaidRequestStatusEnum.PROS &&
      !this._balance
    ) {
      this.balanceMessage = this.translate('prepaidCard.balanceMessage');
    } else if (this.status !== PrepaidRequestStatusEnum.PROS) {
      this.balanceMessage =
        this.translate('prepaidCard.requestStatus') +
        this.requestInfo?.requestId +
        ' ' +
        this.translate('prepaidCard.requestStatus2');
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(PrepaidCardAlertComponent, {
      panelClass: 'custom-modalbox-prepaid',
      enterAnimationDuration: '1500ms',
      exitAnimationDuration: '500ms',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.requestInfo = this.globalService.getPrepaidRequestInfo();
      if (result === 'formSubmitted' || this.requestInfo?.requestId) {
        this.showRequest = false;
        this.showBalance = false;
        this.showStatus = true;
        this.requestId = this.requestInfo?.requestId;
        this.getMessage();
      }
    });
  }
}
