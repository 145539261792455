import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { KipoService } from '@core/kipo.service';
import { BaseComponent, BootService } from '@impesa/ngx-core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent extends BaseComponent implements OnInit {
  @Input()
  inParentGroup!: UntypedFormGroup;

  @Input()
  inIndex: any = null;

  @Input()
  inDefaultLoad: boolean = true;

  @Input()
  accept: string = '.pdf, .jpg, .png, .jpeg';

  @Input()
  attachmentId?: string;

  @Input()
  account?: any;

  @Input()
  attachmentName?: string;

  @Input()
  disable?: boolean = false;

  _file: any;
  public isValidAttachment: boolean = true;
  public attachLimitSize: boolean = false;
  public attachAcceptFormat: boolean = false;
  public attachValidate: string = '';

  constructor(
    boot: BootService,
    private readonly kipoService: KipoService
  ) {
    super(boot);
  }

  @Output() notifyParent: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.attachmentId) {
      this._file = this.attachmentId;
    }

    if (this.inDefaultLoad) {
      this._file = {
        progress: 100,
      };
      return;
    }

    if (this.inIndex === null) {
      this.inParentGroup.addControl(
        'attachment',
        new UntypedFormControl(this.attachmentId ?? '', Validators.required)
      );
    } else {
      const positionForm = (
        this.inParentGroup?.get('companyPartners') as UntypedFormArray
      ).controls[this.inIndex] as UntypedFormGroup;
      positionForm.addControl(
        'attachment',
        new UntypedFormControl(this.attachmentId ?? '', Validators.required)
      );
    }
  }

  public onFileDropped($event: any): void {
    this.validateAttachment($event[0]);
  }

  public fileBrowseHandler(event: any): void {
    this.validateAttachment(event?.target?.files[0]);
  }

  public validateAttachment(item: any) {
    this.attachValidate = '';
    if (item.size > 7000 * 1024) {
      this.attachValidate = this.translate(
        'additionalInfo.attachmentLimitSize'
      );
    } else if (!this.accept.includes(item.type.split('/')[1])) {
      this.attachValidate = this.translate('additionalInfo.attachmentType');
    } else {
      this.prepareFilesList(item);
    }
  }

  public deleteFile(): void {
    if (this.attachmentId) {
      this.kipoService.deleteAttachment(this.attachmentId).subscribe(res => {
        this.attachmentName = '';
        this.notifyParent.emit(res);
      });
    }
    this._file = null;
    if (this.inIndex === null) {
      this.inParentGroup.get('attachment')?.patchValue(null);
    } else {
      const positionForm = (
        this.inParentGroup?.get('companyPartners') as UntypedFormArray
      ).controls[this.inIndex] as UntypedFormGroup;
      positionForm.get('attachment')?.patchValue(null);
    }
  }

  public uploadFilesSimulator(): void {
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (!this._file) {
          return;
        }
        if (this._file.progress === 100) {
          clearInterval(progressInterval);
          this.uploadFilesSimulator();
        } else {
          this._file.progress += 100;
        }
      }, 200);
    }, 1000);
  }

  public prepareFilesList(file: any): void {
    const item = {
      progress: 0,
      buffer: file,
      encoding: '',
      extension: file.type.split('/')[1],
      fieldname: 'data',
      mimetype: file.type,
      name: file.name,
      size: file.size,
    };
    this._file = item;
    this.account = null;

    if (this.inIndex === null) {
      this.inParentGroup.get('attachment')?.patchValue(this._file);
    } else {
      const positionForm = (
        this.inParentGroup?.get('companyPartners') as UntypedFormArray
      ).controls[this.inIndex] as UntypedFormGroup;
      positionForm.get('attachment')?.patchValue(this._file);
    }
    this.notifyParent.emit(true);
    this.uploadFilesSimulator();
  }

  public formatBytes(bytes: any, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public isAttachmentExist(): boolean {
    if (this.inParentGroup.controls['attachment'].errors) {
      this.isValidAttachment = false;
      return this.isValidAttachment;
    } else {
      this.isValidAttachment = true;
      return this.isValidAttachment;
    }
  }
}
