import { Component, OnInit } from '@angular/core';
import { AppService, BaseComponent, BootService } from '@impesa/ngx-core';
import { ServerMessageService } from '@shared/services/server.message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  constructor(
    boot: BootService,
    private readonly appService: AppService,
    private readonly serverMessageService: ServerMessageService
  ) {
    super(boot);
  }
  ngOnInit() {
    this.serverMessageService.listen();
    this.sessionPropsService.sessionSubject.subscribe(session => {
      const country = session?.access_token?.country;
      if (!country) {
        return;
      }
      this.appService.nsp = country;
      localStorage.setItem('country', country);
    });
  }
}
