<div *ngIf="!isMobile" id="navbar_isUser">
  <nav class="navbar navbar-expand-lg navbar-dark" aria-label="Kipo">
    <div class="container">
      <a
        class="navbar-brand internalNav-goBack"
        (click)="goLanding()"
        aria-label="Kipo">
        <img
          class="img-fluid"
          class="d-inline-block align-top img-producto"
          src="./assets/kipo/logo-kipo-blanco-1x.png"
          srcset="./assets/kipo/logo-kipo-blanco-2x.png"
          alt="Kipo Logo" />
      </a>
      <div class="navbar-collapse collapse justify-content-between">
        <ul id="navItems" class="navbar-nav mr-auto">
          <li class="nav-item" *ngIf="showAction">
            <a
              id="nav-withdraw"
              class="nav-link internalNav-outSet-withdraw"
              [routerLink]="['app/withdraw']"
              routerLinkActive="active-link"
              >{{ 'navbar.tabStart' | translate }}</a
            >
          </li>
          <li class="nav-item" *ngIf="showAction">
            <a
              id="nav-transaction"
              class="nav-link internalNav-transaction-history"
              [routerLink]="['app/transaction']"
              routerLinkActive="active-link"
              >{{ 'navbar.tabTransactions' | translate }}</a
            >
          </li>
        </ul>
        <ul id="actionItems" class="navbar-nav">
          <li class="nav-item icon-item">
            <a
              id="nav-profile"
              class="nav-link internalNav-profile"
              routerLinkActive="active-link"
              [routerLink]="['app/profile']"
              matTooltip="{{ 'profile.labelProfile' | translate }}"
              [matTooltipPosition]="'below'">
              <div
                matBadge="!"
                matBadgeColor="warn"
                matBadgeSize="small"
                [matBadgeHidden]="hideMatBadgeKyc">
                <img
                  src="./assets/kipo/nav-ico-profile.svg"
                  alt="Profile icon" />
              </div>
            </a>
          </li>
          <li class="nav-item icon-item notifications">
            <a
              class="nav-link internalNav-notifications"
              (click)="toggleNotification()">
              <span class="num">{{ notifications }}</span>
              <img
                src="./assets/kipo/nav-ico-notification.svg"
                class="notify-ico"
                alt="Notifications icon" />
            </a>
            <app-nav-notificator
              [_notifications]="_notifications"
              (readNotifications)="getNotifications()"
              *ngIf="showNotifications"
              appNgClickOutside
              [showNotifications]="showNotifications"
              (clickOutside)="closeNotification()">
            </app-nav-notificator>
          </li>
          <li>
            <button
              class="btn btn-secondary btn-logout internalNav-logoutBtn"
              [routerLink]="['app/logout']">
              {{ 'navbar.btnLogout' | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<nav
  *ngIf="isMobile === true"
  id="navigation"
  class="navbar navbar-expand-lg navbar-dark"
  aria-label="Kipo">
  <a
    class="navbar-brand ml-3 internalNav-goBack-mobile"
    [routerLink]="['/']"
    aria-label="Kipo">
    <img
      class="img-fluid"
      class="d-inline-block align-top img-producto"
      src="./assets/kipo/logo-kipo-blanco-1x.png"
      srcset="./assets/kipo/logo-kipo-blanco-2x.png"
      alt="Logo Kipo" />
  </a>
  <div class="noti-icon">
    <ul class="navbar-nav nav-mob">
      <li class="nav-item ml-auto notifications">
        <a
          class="nav-link link-notifications internalNav-notifications-mobile"
          (click)="toggleNotification()">
          <span class="num num-mobile">{{ notifications }}</span>
          <img
            src="./assets/kipo/nav-ico-notification.svg"
            class="notify-ico"
            alt="Notifications icon" />
        </a>
      </li>
    </ul>

    <app-nav-notificator
      (clickOutside)="closeNotification()"
      [_notifications]="_notifications"
      appNgClickOutside
      (readNotifications)="getNotifications()"
      *ngIf="showNotifications"></app-nav-notificator>

    <button
      [matMenuTriggerFor]="NavbarMenu"
      class="navbar-toggler navbar-toggler-right toggler-mobile internalNav-mobile-toggleBtn"
      type="button"
      data-toggle="collapse"
      data-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
      id="togglebtn">
      <i class="material-icons menu"> menu </i>
    </button>
  </div>

  <mat-menu #NavbarMenu="matMenu" class="navbar-menu">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a
          class="ml-2 nav-link internalNav-withdraw-mobile"
          [routerLink]="['/app/withdraw']"
          >{{ 'navbar.tabStart' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          id="linkTransac"
          class="ml-2 nav-link internalNav-transaction-mobile"
          [routerLink]="['/app/transaction']"
          >{{ 'navbar.tabTransactions' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          id="linkTransac"
          class="ml-2 nav-link internalNav-profile-mobile"
          [routerLink]="['/app/profile']"
          >{{ 'navbar.tabProfile' | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          id="linkCerrar"
          class="ml-2 nav-link internalNav-logoutBtn-mobile"
          [routerLink]="['app/logout']"
          (click)="toggleNav()"
          >{{ 'navbar.btnLogout' | translate }}</a
        >
      </li>
    </ul>
  </mat-menu>
</nav>
