<div class="form-group mb-0">
  <div class="form-group mb-0" *ngIf="attachment">
    <img
      *ngIf="!showPdf && attachment && URL"
      width="50"
      height="50"
      [src]="URL | safeUrl"
      alt="{{ fileName }}"
      appImageViewer />
    <button
      *ngIf="showPdf"
      mat-button
      color="primary"
      (click)="showAttachment()">
      <mat-icon class="mat-icon-2x">picture_as_pdf</mat-icon>
    </button>
  </div>
</div>
