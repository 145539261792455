import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Injectable({
  providedIn: 'root',
})
export class UserInfoResolver {
  constructor(private readonly kipoService: KipoService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve(_route: ActivatedRouteSnapshot): Observable<any> {
    const obsArg = {
      data: this.kipoService.getUserData(),
    };

    return forkJoin(obsArg);
  }
}
