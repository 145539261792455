<iframe
  title="Preview Document"
  alt="Preview Document"
  [src]="this.fileDetail.URL"
  *ngIf="this.fileDetail.URL && this.fileDetail.showPdf"
  width="100%"
  height="100%"
  style="overflow: hidden">
</iframe>
<img
  *ngIf="!this.fileDetail.showPdf"
  [src]="this.fileDetail.URL"
  alt="attachment img" />
