<div class="box">
  <div class="notifications">
    <a class="row">
      <div class="col-sm-4 offset-4 pt-2">
        <img
          width="70"
          src="./assets/kipo/logo-kipo-gray.svg"
          alt="Kipo icon" />
      </div>
      <div class="col-sm-4 pt-3">
        <i
          title="Remover todo"
          aria-hidden="true"
          (click)="readUserNotifications()"
          (keyPress)="readUserNotifications()"
          class="material-icons float-right icon-close-all navNotificator-clearAll-notifications">
          clear_all
        </i>
      </div>
    </a>
    <ul class="notify">
      <li
        class="icon justify-content-between"
        *ngFor="let item of _notifications">
        <span
          class="text"
          aria-hidden="true"
          (click)="validateMessageType(item.description)"
          (keyPress)="validateMessageType(item.description)"
          >{{ item.description }}</span
        >
        <i
          title="Close"
          aria-hidden="true"
          (click)="readNotification(item.id)"
          (keyPress)="readNotification(item.id)"
          class="material-icons icon-close-item navNotificator-closeItem">
          highlight_off
        </i>
      </li>
    </ul>
  </div>
</div>
