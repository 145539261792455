import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from '@components/logout/logout.component';
import { PaypalCallbackComponent } from '@components/paypal/callback/callback.component';
import { ProfileComponent } from '@components/profile/profile.component';
import { HistoryComponent } from '@components/transaction/history/history.component';
import { WithdrawComponent } from '@components/transaction/withdraw/withdraw.component';
import { AuthGuard } from '@impesa/ngx-core';
import { resolverKycServiceFn } from '@impesa/ngx-kyc';

import { CountrySettingsResolverFn } from '../../../app/resolver/country-settings.resolver';
import { KipoLimitsResolver } from '../../resolver/kipo-limits.resolver';
import { OriginAccountsResolver } from '../../resolver/origin-accounts.resolver';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'paypal/callback', component: PaypalCallbackComponent },
      {
        path: 'profile',
        component: ProfileComponent,
        resolve: {
          data: resolverKycServiceFn,
          countrySettingsResolve: CountrySettingsResolverFn,
        },

        canActivate: [AuthGuard],
      },
      {
        path: 'withdraw',
        component: WithdrawComponent,
        resolve: {
          countrySettingsResolve: CountrySettingsResolverFn,
          kipoLimits: KipoLimitsResolver,
          originAccounts: OriginAccountsResolver,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'transaction',
        component: HistoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivateRoutingModule {}
