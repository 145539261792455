import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAllowOnlyNumber]',
})
export class AllowOnlyNumbersDirective {
  @Input() allowDecimalAndComma: boolean = false;

  constructor(private control: NgControl) {}
  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    let inputValue = (event.target as HTMLInputElement).value;
    if (this.allowDecimalAndComma) {
      inputValue = inputValue.replace(/[^0-9.]/g, '');

      const parts = inputValue.split('.');
      if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 2);
        inputValue = parts[0] + '.' + parts[1];
      }
    } else {
      inputValue = inputValue.replace(/\D/g, '');
    }
    (event.target as HTMLInputElement).value = inputValue;
    this.control.control?.setValue(inputValue);
  }

  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text/plain');
    if (pastedText && !/^\d*$/.test(pastedText)) {
      (event.target as HTMLInputElement).value = pastedText.replace(/\D/g, '');
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Escape',
      'Enter',
      'Delete',
      'Home',
      'End',
      'ArrowLeft',
      'ArrowRight',
      'Clear',
    ];
    const isAllowedKey = allowedKeys.includes(event.key);

    if (
      !this.allowDecimalAndComma &&
      !isAllowedKey &&
      !/^\d$/.test(event.key)
    ) {
      event.preventDefault();
    }
  }
}
