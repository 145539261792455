<div
  class="d-flex justify-content-center align-items-center height d-flex flex-column height">
  <mat-icon class="logo-icon text"> cancel</mat-icon>
  <p class="font-weight-bold text h5">
    {{ 'withdraw.alertRejectedKyc' | translate }}
    <a
      href="mailto: {{ 'home.footer.emailAddress' | translate }}"
      class="alert-rejectedKyc-disclaimer"
      >{{ 'withdraw.disclaimerTransactionMail' | translate }}</a
    >
  </p>
</div>
