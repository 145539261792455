import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgxMaterialModule } from '@impesa/ngx-core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-usr-alert-acc',
  standalone: true,
  imports: [CommonModule, NgxMaterialModule, TranslateModule],
  templateUrl: './usr-alert-acc.component.html',
  styleUrls: ['./usr-alert-acc.component.scss'],
})
export class UsrAlertACCComponent {}
