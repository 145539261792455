<div class="pt-3 pb-3">
  <div class="container">
    <div class="alert-dialog mb-3" *ngIf="showAlert">
      <p class="alertForm">
        {{ 'profile.labelAlertForm' | translate }}
        <br />
        {{ 'profile.labelCompleteForm' | translate }}
      </p>
    </div>

    <div class="panel">
      <div class="row">
        <div
          style="word-break: break-word"
          class="col-md-3 border-right"
          *ngIf="getIdNumberType">
          <div id="parent" *ngIf="!isMobile">
            <div id="child" class="padding">
              <img
                class="logo-kipo mb-3"
                src="../../../assets/kipo/kipo-logo-fondo-azul.svg"
                alt="kipo-logo" />
              <span class="form-control mb-2">{{
                'profile.labelWellcomeKipo' | translate
              }}</span>
              <div class="username-text pt-4">
                <p class="form-control text-center">
                  {{ userProfile?.username }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          [ngClass]="getIdNumberType ? 'col-md-9' : 'col-md-12'"
          class="padding-card">
          <mat-tab-group
            dynamicHeight
            animationDuration="1000ms"
            (selectedTabChange)="tabChanged($event)"
            mat-align-tabs="start"
            [selectedIndex]="selected.value">
            >

            <mat-tab
              *ngIf="getIdNumberType || showProfile"
              label="{{ 'profile.labelProfile' | translate }}">
              <div *ngIf="getIdNumberType">
                <div class="row mt-5">
                  <div class="col-md-12" style="color: black">
                    {{ 'profile.labelRegistrationContinue' | translate }}
                  </div>
                </div>
                <div class="row mt-5 newAcount">
                  <div class="col-12">
                    <h5>
                      {{ 'profile.labelRegistrationType' | translate }}
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <mat-radio-group>
                    <div class="col-12" *ngFor="let item of clientTypesList">
                      <mat-radio-button
                        (change)="radioChange(item)"
                        value="{{ item.code }}"
                        class="profile-selectTypeOfRegister"
                        id="{{ item.code }}">
                        {{ item.description }}
                      </mat-radio-button>
                    </div>
                  </mat-radio-group>
                </div>
                <br />
                <div>
                  <small style="color: black">
                    {{ 'profile.labelRegistrationForeign' | translate }}
                  </small>
                </div>
              </div>
              <div *ngIf="!getIdNumberType && showProfile">
                <lib-profile
                  [clientType]="clientType"
                  redirectURL="app/withdraw"></lib-profile>
              </div>
            </mat-tab>
            <mat-tab
              *ngIf="!getIdNumberType && showKyc"
              label="{{ 'profile.aditionalInfo' | translate }}">
              <div class="panel-lib">
                <lib-kyc
                  title="{{ 'profile.labelRegistrationContinue' | translate }}"
                  appereanceField="outline"
                  [identificationTypes]="this.identificationTypesAllowed"
                  [email]="this.userName"
                  [emailEditable]="false"
                  [isOtpRequired]="false"
                  [preValidation]="prevalidationFn"
                  [clientType]="clientType"
                  [showSuccessfulMessage]="false"
                  [redirectURL]="'app/withdraw'"
                  (kycSaved)="kycSaved($event)"></lib-kyc>
              </div>
            </mat-tab>

            <mat-tab
              *ngIf="countrySettings.showBilling"
              [disabled]="isCustomBilling"
              class="row"
              label="{{ 'profile.labelBilling' | translate }}">
              <lib-billings [_clientType]="clientType"> </lib-billings>
            </mat-tab>
          </mat-tab-group>

          <div class="row">
            <div class="col-3 mx-auto pt-4">
              <button
                (click)="saveIdNumberType()"
                *ngIf="getIdNumberType"
                [disabled]="!idNumberType"
                class="btn btn-secondary profile-saveIdNumberType">
                {{ 'profile.labelButtonContinue' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <app-footer></app-footer>
  </div>
</div>
