<div class="mt-4 mb-4 mx-4">
  <div *ngIf="data.code === 'NOT_MATCH_ERROR'" class="alert-dialog">
    <p>Estimad&#64; cliente:</p>
    <p>
      Hemos verificado que el correo de tu cuenta PayPal no coincide con el
      correo registrado en KIPO. Para continuar con el proceso de vinculación es
      requerido que el registro en ambas plataformas haya sido realizado con el
      mismo correo electrónico.
    </p>
  </div>

  <div
    *ngIf="data.code === 'INTERNAL_ERROR_PAYPAL_STATUS_USER'"
    class="alert-dialog">
    <p>Estimad&#64; cliente:</p>
    <p>
      Tu cuenta PayPal no se encuentra verificada. Kipo requiere que la cuenta
      lo sea. <br />
      Es necesario que completes la información solicitada por PayPal para
      continuar con tus transferencias.
      <br />
      Para más información comunícate con PayPal al
      <a href="tel:0800 011 1284">0800-011-1284</a>
    </p>
  </div>

  <div
    *ngIf="data.code === 'INTERNAL_ERROR_PAYPAL_COUNTRY_NOT_ALLOWED'"
    class="alert-dialog">
    <p>Estimad&#64; cliente:</p>
    <p>
      Hemos verificado tu cuenta y la misma se encuentra vinculada a otro país.
      KIPO solo te permite vincular cuentas PayPal domiciliadas en
      {{ 'withdraw.alert.country' | translate }}. Para crear una nueva cuenta
      PayPal {{ 'withdraw.alert.country' | translate }}, podrás realizarlo desde
      el siguiente enlace:
      <a
        href="{{ 'withdraw.alert.paypalUrl' | translate }}"
        target="_blank"
        rel="noopener"
        >{{ 'withdraw.alert.paypalUrl' | translate }}</a
      >. Posteriormente deberás transferir el saldo de tu cuenta PayPal a la
      nueva cuenta con domicilio en {{ 'withdraw.alert.country' | translate }}.
      Si necesitas asistencia, puedes contactarte al número
      <a href="tel:0800-011-1284">0800-011-1284</a> de PayPal o escribirnos al
      <a href="tel:{{ 'withdraw.alert.phoneNumberLink' | translate }}">{{
        'withdraw.alert.phoneNumber' | translate
      }}</a
      >.
    </p>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <button
        mat-button
        mat-dialog-close
        class="btn btn-secondary mx-auto dialog-closeBtn">
        {{ 'withdraw.close' | translate }}
      </button>
    </div>
  </div>
</div>
