<div
  class="d-flex justify-content-center align-items-center height d-flex flex-column height">
  <mat-icon class="logo-icon text"> cancel</mat-icon>
  <p class="font-weight-bold text h5">
    @switch (country) {
      @case ('CRI') {
        {{ 'withdraw.alertInactiveUser.title' | translate }} <br /><br />
        {{ 'withdraw.alertRejectedKyc' | translate }}
        <a
          href="{{ 'withdraw.alert.whatsappNumberLink' | translate }}"
          target="_blank"
          rel="noopener"
          >{{ 'withdraw.alert.whatsappTitle' | translate }}</a
        ><br /><br />
        {{ 'withdraw.alert.footer' | translate }}
      }
      @default {
        {{ 'withdraw.alertRejectedKyc' | translate }}
        <a
          href="mailto: {{ 'withdraw.disclaimerTransactionMail' | translate }}"
          class="alert-rejectedKyc-disclaimer"
          >{{ 'withdraw.disclaimerTransactionMail' | translate }}</a
        >
      }
    }
  </p>
</div>
