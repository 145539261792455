import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { KipoService } from '@core/kipo.service';
import { UserStatusesEnum } from '@enums/user-statuses.enum';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';

import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-navbar',
  templateUrl: './internal-navbar.component.html',
  styleUrls: ['./internal-navbar.component.scss'],
})
export class InternalNavbarComponent implements OnInit {
  isMobile = false;
  showNotifications = false;

  notifications: number = 0;
  @Output() _notifications!: any[];
  @Output() public appNgclickoutside = new EventEmitter();

  private userProfile!: any;
  public showAction: boolean = false;
  public hideMatBadgeKyc: boolean = true;

  constructor(
    private readonly kipoService: KipoService,
    private readonly globalService: GlobalVarsService
  ) {}

  ngOnInit() {
    if (window.screen.width <= 768) {
      this.isMobile = true;
    }
    this.createNotificationListener();
    this.getNotifications();
    this.initProfileChangesEvent();
  }

  createNotificationListener() {
    this.globalService.getNotify().subscribe(
      (notifications: any) => {
        if (notifications && notifications.length > 0) {
          this._notifications = notifications;
          this.notifications = notifications.length;
        } else {
          this._notifications = [];
          this.notifications = 0;
        }
      },
      (err: any) => {
        this.globalService.errorMessage(err.error.message || err.message);
      }
    );
  }

  getNotifications() {
    this.kipoService.getNotifications().subscribe({
      next: notifications => {
        if (notifications) {
          this._notifications = notifications;
          this.notifications = notifications.length;
          this.globalService.setNotificationList(notifications);
        } else {
          this.showNotifications = false;
          this._notifications = [];
          this.notifications = 0;
        }
      },
      error: (err: any) => {
        this.globalService.errorMessage(err.error.message || err.message);
      },
    });
  }

  initProfileChangesEvent() {
    this.showAction = this.isBasicFormCompleted();
    this.globalService.getUserProfile().subscribe(
      (profile: any) => {
        this.showAction = this.isBasicFormCompleted(profile);
        this.hideMatBadgeKyc = true;
        if (
          profile.status === UserStatusesEnum.REG &&
          profile.enableToTransact === '0'
        ) {
          this.hideMatBadgeKyc = false;
        }
      },
      (err: any) => {
        this.globalService.errorMessage(err.error.message || err.message);
      }
    );
  }

  isBasicFormCompleted(profile?: any): boolean {
    this.userProfile = profile || this.globalService.getProfile();
    if (this.userProfile?.identificationType) {
      return true;
    }
    return false;
  }

  toggleNav() {
    const navCollapse = document.getElementById('togglebtn');
    if (navCollapse) {
      navCollapse.click();
    }
  }

  toggleNotification() {
    this.showNotifications = !this.showNotifications;
  }

  closeNotification() {
    this.showNotifications = !this.showNotifications;
  }

  goLanding() {
    let url = environment.landingUrl;
    const currentCountry =
      localStorage.getItem('country')?.slice(0, -1).toLocaleLowerCase() ?? '';
    url = url.replace('country', currentCountry);
    window.location.href = url;
  }
}
