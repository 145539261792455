import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { forkJoin } from 'rxjs';

export const CountrySettingsResolverFn: ResolveFn<any> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _state: RouterStateSnapshot
) => {
  const resolverKipoService = inject(KipoService);

  const obsArg = {
    countrySettings: resolverKipoService.getCountrySettingsByCode(
      resolverKipoService.getCountry()
    ),
  };

  return forkJoin(obsArg);
};
