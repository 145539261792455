import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

import { KycAlertINAComponent } from '../kyc-alert-ina/kyc-alert-ina.component';
import { KycAlertREGComponent } from '../kyc-alert-reg/kyc-alert-reg.component';
import { KycAlertREJComponent } from '../kyc-alert-rej/kyc-alert-rej.component';
import { KycAlertREVComponent } from '../kyc-alert-rev/kyc-alert-rev.component';
import { UsrAlertACCComponent } from '../usr-alert-acc/usr-alert-acc.component';

@Component({
  selector: 'app-kyc-alert',
  standalone: true,
  imports: [
    CommonModule,
    KycAlertREGComponent,
    KycAlertREVComponent,
    KycAlertREJComponent,
    KycAlertINAComponent,
    UsrAlertACCComponent,
  ],
  templateUrl: './kyc-alert.component.html',
  styleUrl: './kyc-alert.component.scss',
})
export class KycAlertComponent {
  showKycAlertREG = input<boolean | undefined>();
  showKycAlertREV = input<boolean | undefined>();
  showKycAlertREJ = input<boolean | undefined>();
  showKycAlertINA = input<boolean | undefined>();
  showUsrAlertACC = input<boolean | undefined>();
}
