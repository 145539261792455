import { Component, OnInit } from '@angular/core';
import { AuthService } from '@impesa/ngx-core';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly globals: GlobalVarsService
  ) {}

  ngOnInit() {
    this.globals.deleteCookie();
    this.authService.logout();
    const country = this.getCountry();
    localStorage.clear();
    const envPrefix = this.getEnvPrefix();
    window.location.href = this.getRedirectUrl(envPrefix, country);
  }

  getCountry() {
    return localStorage.getItem('country')?.toLowerCase().slice(0, 2) ?? '';
  }

  getEnvPrefix() {
    if (window.location.hostname.includes('dev')) {
      return 'dev-';
    } else if (window.location.hostname.includes('stag')) {
      return 'stag-';
    }
    return '';
  }

  getRedirectUrl(envPrefix: string, country: string) {
    if (envPrefix) {
      return `https://${envPrefix}${country}.getkipo.com`;
    }
    return `https://getkipo.com`;
  }
}
