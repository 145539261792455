import { file } from '../file.model';
export class destinationAccount {
  alias!: string;
  account!: string;
  typeId!: string;
  issuerId!: string;
  status?: string;
  userId?: string;
}

export class updateDestinationAccount {
  alias!: string;
  accountId!: string;
  account!: string;
  typeId!: string;
  issuerId!: string;
  status?: string;
  userId?: string;
}

export interface UpdateDestinationAccountStatusDto {
  status: string;
}

export class destinationAccountResponse {
  alias!: string;
  accountId!: string;
  userId!: string;
  iban!: string;
  ownerName!: string;
  ownerId!: string;
  currency_code!: string;
  status!: string;
  description!: string;
}

export class destinationAccountTypesResponse {
  id!: string;
  name!: string;
  country!: string;
  value!: string;
}

export class attachmentResponse {
  accountId!: string;
  path!: string;
}

export class filesList {
  destination_acount_document!: file;
  front_id!: file;
  back_id!: file;
}
