export enum identificationTypesEnum {
  CI = 'CI',
  PAS = 'PAS',
  RUC = 'RUC',
}

export enum clientTypesEnum {
  PERSON = 'F',
  BUSINESS = 'J',
}
