<div *ngIf="template.withdrawIcon?.whatsappIcon">
  <a
    [href]="
      'https://api.whatsapp.com/send/?phone=' +
        ('withdraw.disclaimerContactMobile' | translate) | safeUrl
    "
    rel="noopener"
    target="_blank">
    <svg viewBox="0 0 32 32" class="whatsapp-icon">
      <path
        d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
        fill-rule="evenodd"></path>
    </svg>
  </a>
</div>

<div class="container">
  <div class="row">
    <div class="col-8 mx-auto text-center"></div>
  </div>

  <form
    [ngClass]="{ 'd-none': _showAddIbanForm }"
    class="form-withdraw"
    [formGroup]="_withdrawForm"
    #formDir="ngForm">
    <div class="row">
      <div class="col-md-12 pt-3">
        <mat-horizontal-stepper
          class="desktopView"
          *ngIf="!_viewInMobileMode"
          linear
          #stepper>
          <ng-template matStepperIcon="edit">
            <mat-icon>done </mat-icon>
          </ng-template>

          <mat-step>
            <ng-template matStepLabel>{{
              'withdraw.stepOne' | translate
            }}</ng-template>

            <ng-container *ngTemplateOutlet="transacctionForm"></ng-container>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>{{
              'withdraw.stepTwo' | translate
            }}</ng-template>
            <div
              class="col-sm-12 col-xs-12 col-md-6 offset-3 pt-3"
              *ngIf="_transaction">
              <app-confirm
                (callParentBack)="stepperBack()"
                (callParentSave)="saveWithdraw()"
                [stepper]="stepper"
                [transaction]="_transaction"
                [IVA]="this._taxes"
                [isLoading]="isLoading"
                [accumulateFeeValue]="accumulateFeeValue"></app-confirm>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>{{
              'withdraw.stepThree' | translate
            }}</ng-template>
            <div class="pt-3">
              <div
                id="voucherTransaction"
                class="col-md-6 w-auto m-auto voucher"
                *ngIf="_transaction && _transaction.withdrawResult">
                <app-voucher
                  class="voucher-content"
                  id="voucherArea"
                  [transaction]="_transaction"
                  [issuerName]="_issuerName"
                  (callPrintParent)="printVoucher()"
                  (resetComponent)="resetComponent()"
                  [IVA]="this._taxes"
                  [accumulateFeeValue]="accumulateFeeValue"></app-voucher>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>

        <mat-vertical-stepper
          class="mobile-view"
          *ngIf="_viewInMobileMode"
          linear
          #stepper>
          <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
          </ng-template>

          <mat-step>
            <ng-template matStepLabel>{{
              'withdraw.stepOne' | translate
            }}</ng-template>
            <ng-container *ngTemplateOutlet="transacctionForm"></ng-container>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>{{
              'withdraw.stepTwo' | translate
            }}</ng-template>

            <div class="row d-flex justify-content-center">
              <div
                class="col-sm-12 col-xs-12 col-md-10 offset-3 pt-3"
                *ngIf="_transaction">
                <app-confirm
                  (callParentBack)="stepperBack()"
                  (callParentSave)="saveWithdraw()"
                  [stepper]="stepper"
                  [transaction]="_transaction"
                  [isLoading]="isLoading"
                  [IVA]="this._taxes"
                  [accumulateFeeValue]="accumulateFeeValue"></app-confirm>
              </div>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>{{
              'withdraw.stepThree' | translate
            }}</ng-template>
            <div class="pt-3 d-flex justify-content-center">
              <div
                id="voucherTransaction"
                class="col-md-8"
                *ngIf="_transaction && _transaction.withdrawResult">
                <app-voucher
                  id="voucherArea"
                  [transaction]="_transaction"
                  [issuerName]="_issuerName"
                  (callPrintParent)="printVoucher()"
                  (resetComponent)="resetComponent()"
                  [accumulateFeeValue]="accumulateFeeValue">
                </app-voucher>
              </div>
            </div>
          </mat-step>
        </mat-vertical-stepper>

        <ng-template #transacctionForm>
          <div
            class="row pt-3"
            [ngClass]="!_viewInMobileMode ? 'accountBalance' : ''">
            <div
              class="mb-3 row-gap d-flex"
              [ngClass]="{
                'col-md-12': _viewInTabletMode,
                'col-md-3 pl-1': !_viewInTabletMode && !_viewInMobileMode,
                'pl-3 pr-3 w-100': _viewInMobileMode,
              }">
              <div class="panel w-100" *ngIf="_PrepaidAccount && _card">
                <h1 class="text-center mb-3">
                  {{ 'withdraw.panelTitleOne' | translate }}
                </h1>
                <p class="sub-title mb-3 text">
                  {{ 'withdraw.panelSubTitleOne' | translate }}
                </p>
                <app-accounts-balance
                  [_payPalAccount]="_PayPalAccount"
                  [_payPalBalance]="_PayPalBalance"
                  [_formLoadError]="_formLoadError"
                  [_withdrawForm]="_withdrawForm"
                  (cardValueEmitted)="handleCardValue($event)"
                  class="h-100 d-flex w-100"></app-accounts-balance>
              </div>
              <app-accounts-balance
                *ngIf="!_card"
                [_payPalAccount]="_PayPalAccount"
                [_payPalBalance]="_PayPalBalance"
                [_formLoadError]="_formLoadError"
                [_withdrawForm]="_withdrawForm"
                (cardValueEmitted)="handleCardValue($event)"
                class="h-100 d-flex w-100"></app-accounts-balance>
            </div>
            <div
              class="mb-3"
              ngClass="{{ _viewInTabletMode ? 'col-md-6' : 'col-md-4' }}">
              <div class="panel">
                <h1 class="mb-3 text-center">
                  {{ 'withdraw.panelTitleTwo' | translate }}
                </h1>
                <p class="sub-title mb-3 text">
                  {{ 'withdraw.panelSubTitleTwo' | translate }}
                </p>

                <mat-selection-list
                  class="cards"
                  [disabled]="_formLoadError"
                  #sinpeCards
                  [multiple]="false">
                  <div
                    *ngFor="let sinpeAcc of _SinpeAccounts"
                    [matTooltip]="sinpeAcc.isDisabled ? label : ''"
                    [ngClass]="sinpeAcc.isDisabled ? 'not-allowed' : ''">
                    <mat-list-option
                      [ngClass]="sinpeAcc.isDisabled ? 'not-allowed' : ''"
                      [ngClass]="{
                        selected: sinpeAcc === _SelectSinpe,
                        'no-selected': sinpeAcc !== _SelectSinpe,
                        'real-time': sinpeAcc.trxnRealTime,
                        'pending-accounts': sinpeAcc.status === 'PENDI',
                      }"
                      id="{{ sinpeAcc.accountId }}"
                      class="mb-2 withdraw-selectSinpeAcc real-time pending-accounts"
                      [selected]="
                        sinpeAcc.accountId ===
                        (_SelectSinpe ? _SelectSinpe.accountId : '')
                      "
                      (click)="setSelectedSinpe(sinpeAcc)"
                      [disabled]="sinpeAcc.isDisabled"
                      (onKeyPress)="setSelectedSinpe($event)"
                      (keydown)="setSelectedSinpe($event)">
                      <div
                        class="d-flex flex-row-reverse justify-content-end center-box">
                        <h4 class="space">
                          {{ sinpeAcc.iban }}
                        </h4>
                        <div *ngIf="sinpeAcc.status === 'ACTIV'">
                          <p
                            class="real-time-text"
                            *ngIf="sinpeAcc.trxnRealTime">
                            {{ 'sinpe.realTime' | translate }}
                          </p>
                          <p class="account word-wrap">
                            {{ sinpeAcc.account }}
                          </p>
                          <p>
                            {{ sinpeAcc.issuer }}
                          </p>
                          <p>
                            {{ sinpeAcc.alias }}
                          </p>
                        </div>

                        <div *ngIf="sinpeAcc.status !== 'ACTIV'">
                          <p class="account word-wrap">
                            {{ sinpeAcc.account }}
                          </p>
                          <p>
                            {{ sinpeAcc.issuer }}
                          </p>
                          <p
                            *ngIf="
                              sinpeAcc.status === 'PENDI' &&
                              !sinpeAcc.trxnRealTime
                            ">
                            {{ 'withdraw.panelLabelPendiIban' | translate }}
                          </p>
                          <p
                            *ngIf="
                              sinpeAcc.status === 'PENDI' &&
                              sinpeAcc.trxnRealTime
                            ">
                            {{
                              'withdraw.panelLabelPendiIbanRealTime' | translate
                            }}
                          </p>

                          <p *ngIf="sinpeAcc.status !== 'PENDI'">
                            {{ sinpeAcc.description }}.
                          </p>
                        </div>
                        <div
                          class="d-flex flex-column justify-content-center pr-2">
                          <mat-icon
                            class="iban-icon"
                            matListIcon
                            *ngIf="
                              sinpeAcc.status === 'PENDI' ||
                              sinpeAcc.status === 'TOAPR'
                            "
                            matTooltip="{{
                              'withdraw.panelLabelTooltipEdit' | translate
                            }}"
                            (click)="editAccount(sinpeAcc)"
                            (onKeyPress)="editAccount(sinpeAcc)"
                            (keydown)="editAccount(sinpeAcc)">
                            edit
                          </mat-icon>

                          <mat-icon
                            class="iban-icon"
                            matListIcon
                            *ngIf="sinpeAcc.status === 'ACTIV'"
                            matTooltip="{{
                              'withdraw.panelLabelTooltipAct' | translate
                            }}">
                            credit_card
                          </mat-icon>

                          <mat-icon
                            class="iban-icon"
                            matListIcon
                            *ngIf="
                              sinpeAcc.status !== 'ACTIV' &&
                              sinpeAcc.status !== 'PENDI' &&
                              sinpeAcc.status !== 'TOAPR'
                            "
                            (click)="deleteIban(sinpeAcc)"
                            (onKeyPress)="deleteIban(sinpeAcc)"
                            (keydown)="deleteIban(sinpeAcc)"
                            matTooltip="{{
                              'withdraw.panelLabelTooltip' | translate
                            }}"
                            >cancel
                          </mat-icon>

                          <mat-icon
                            class="real-time"
                            matListIcon
                            *ngIf="sinpeAcc.trxnRealTime"
                            matTooltip="{{
                              'withdraw.panelLabelTooltipRealTime' | translate
                            }}">
                            bolt
                          </mat-icon>

                          <mat-icon
                            class="inactivate"
                            matListIcon
                            *ngIf="
                              sinpeAcc.status === 'ACTIV' &&
                              !sinpeAcc.trxnRealTime
                            "
                            matTooltip="{{
                              'withdraw.inactiveAccount' | translate
                            }}"
                            (click)="handleInactivateAccount(sinpeAcc)"
                            (onKeyPress)="handleInactivateAccount(sinpeAcc)"
                            (keydown)="handleInactivateAccount(sinpeAcc)">
                            cancel
                          </mat-icon>
                        </div>
                      </div>
                    </mat-list-option>
                  </div>
                </mat-selection-list>

                <div>
                  <input
                    type="hidden"
                    formControlName="sinpeAccountId"
                    name="sinpeAccountId"
                    class="withdraw-sinpeAccountId"
                    value="{{ _SelectSinpe ? _SelectSinpe.accountId : '' }}" />
                  <input
                    type="hidden"
                    formControlName="paypalAccountId"
                    name="paypalAccountId"
                    class="withdraw-paypalAccountId"
                    value="{{
                      _PayPalAccount ? _PayPalAccount.accountId : ''
                    }}" />
                </div>

                <div class="act-wrapper">
                  <p *ngIf="!_SinpeAccounts.length">
                    {{ 'withdraw.panelLabelAlertSinpe' | translate }}
                  </p>
                  <button
                    class="btn btn-secondary btn-block withdraw-addAccountBtn"
                    type="button"
                    (click)="addAccount()"
                    [disabled]="_formLoadError">
                    {{ 'withdraw.panelButtonTwo' | translate }}
                  </button>
                </div>
              </div>
            </div>

            <div
              class="mb-3"
              ngClass="{{ _viewInTabletMode ? 'col-md-6' : 'col-md-5' }}">
              <div
                class="panel panel-long"
                *ngIf="
                  !showAlertReview &&
                  !showAlertRejected &&
                  !showAlertCompleteKyc &&
                  !showAlertPendingAccount &&
                  !showAlertInactive &&
                  showWithdraw
                ">
                <h1 class="mb-3 text-center">
                  {{ 'withdraw.panelTitleThree' | translate }}
                </h1>
                <p class="sub-title mb-3 text">
                  {{ 'withdraw.panelSubTitleThree' | translate }}
                </p>
                <div>
                  <app-calculator-form
                    (accumulateFeeEvent)="handleFeeValue($event)"
                    position="withdraw"
                    (valueChange)="getAmountValue($event)"
                    [isAmountEnable]="
                      showDisclaimerAditionalInfo
                    "></app-calculator-form>

                  <p
                    class="sub-disclaimer text px-10"
                    *ngIf="!_SelectSinpe?.trxnRealTime">
                    {{ 'withdraw.panelLabelThreeInfo' | translate }}
                  </p>
                  <p
                    class="sub-disclaimer text px-10"
                    *ngIf="_SelectSinpe?.trxnRealTime">
                    {{ 'withdraw.panelLabelThreeRealTime' | translate }}
                  </p>
                  <p class="sub-disclaimer text px-10" *ngIf="this._KipoLimits">
                    {{ 'withdraw.limitDescriptionDay' | translate }}
                    {{ this._KipoLimits?.dayLimit | currency: 'USD' }}
                    {{ 'withdraw.limitAnd' | translate }}
                    {{ this._KipoLimits?.monthLimit | currency: 'USD' }}
                    {{ 'withdraw.limitDescriptionMonth' | translate }}
                  </p>
                  <p class="sub-disclaimer mt-1 text pb-0">
                    {{ 'withdraw.disclaimerTransactionContact' | translate }}
                    <a
                      [href]="
                        'withdraw.disclaimerContactLink2' | translate | safeUrl
                      ">
                      {{ 'withdraw.disclaimerContact3' | translate }}
                    </a>
                  </p>
                  <div class="select-account">
                    <p *ngIf="_withdrawForm.get('sinpeAccountId')?.errors">
                      {{ 'withdraw.selectAccount' | translate }}
                    </p>
                  </div>
                  <div class="act-wrapper">
                    <button
                      class="btn btn-secondary btn-block withdraw-confirmStepBtn"
                      [disabled]="_withdrawForm.invalid || _formLoadError"
                      type="button"
                      (click)="goToConfirmStep()">
                      {{ 'withdraw.panelButtonThree' | translate }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="panel panel-long"
                *ngIf="
                  showAlertReview ||
                  showAlertRejected ||
                  showAlertCompleteKyc ||
                  showAlertPendingAccount ||
                  showAlertInactive
                ">
                <app-review-kyc *ngIf="showAlertReview"></app-review-kyc>
                <app-rejected-kyc *ngIf="showAlertRejected"></app-rejected-kyc>
                <app-inactive-user *ngIf="showAlertInactive">
                </app-inactive-user>
                <app-complete-kyc
                  [viewLabel]="showViewInfoLabel"
                  *ngIf="showAlertCompleteKyc"
                  ngClass="{{
                    showWithdraw ? 'reduce-padding' : 'regular-padding'
                  }}"></app-complete-kyc>
                <app-account-pending *ngIf="showAlertPendingAccount">
                </app-account-pending>
              </div>
            </div>
          </div>
          <app-disclaimer></app-disclaimer>
        </ng-template>
      </div>
    </div>
    <app-footer> </app-footer>
  </form>
</div>
