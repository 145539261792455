@if (showKycAlertREG()) {
  <app-kyc-alert-reg class="alert-container" />
}
@if (showKycAlertREV()) {
  <app-kyc-alert-rev class="alert-container" />
}
@if (showKycAlertREJ()) {
  <app-kyc-alert-rej class="alert-container" />
}
@if (showKycAlertINA()) {
  <app-kyc-alert-ina class="alert-container" />
}
@if (showUsrAlertACC()) {
  <app-usr-alert-acc class="alert-container" />
}
