import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly globalVarsService: GlobalVarsService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/logout')) {
      this.globalVarsService.deleteCookie();
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(
          `An error occurred: Status - ${error.status}, Message - ${error.message}`
        );

        return throwError(() => error);
      })
    );
  }
}
