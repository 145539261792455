import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { PRODUCT_TYPES } from '@enums/product-types.enum';
import { OriginAccount } from '@models/origin-accounts.model';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OriginAccountsResolver {
  constructor(
    private readonly kipoService: KipoService,
    private readonly globalService: GlobalVarsService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve(_route: ActivatedRouteSnapshot): Observable<OriginAccount[]> {
    const obsArg = {
      paypalAccounts: this.kipoService.getUserPayPalAccounts(),
      prepaidAccounts: this.kipoService.getUserOriginAccounts(
        PRODUCT_TYPES.PREPAID
      ),
    };

    return forkJoin(obsArg).pipe(
      catchError(error => {
        this.globalService.showWarningMessage(
          error.error.message || error.message
        );
        return of(null as any);
      })
    );
  }
}
