import { Component, OnInit } from '@angular/core';
import { KipoService } from '@core/kipo.service';
import { environment } from '@environment/environment';
import { Notices } from '@models/notices.model';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
  public environment = environment;
  public notices!: Notices[];
  public showDisclaimer = false;

  constructor(private readonly kipoService: KipoService) {}

  ngOnInit(): void {
    this.kipoService.getNotices().subscribe(result => {
      this.notices = result;
      if (result.length > 0) {
        this.showDisclaimer = true;
      }
    });
  }
}
